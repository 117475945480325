@import url(./fontawesome/css/all.css);

:root {
  --bodysize: 16px;
  --pfamily: "Circular Std";
  --pboldfamily: "CircularStdBold";
  --box-shadow: 0 3px 6px #a8a8a8;
  --base-font-size: 1em !important;
  --base-line-height: 1.5;
  --main-green-color: #6e9d75 !important;
  --default-color: #5e5e5e !important;
  --background-color: #f3f3f3;
  --text: #676a6c;
  --white: #fff !important;
  --black: #222;
}

.font-weight-bold {
  font-family: var(--pboldfamily);
}

body {
  font-size: var(--bodysiize);
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--pfamily);
  color: var(--text) !important;
  overflow-x: hidden;
  top: 0 !important;
  background-color: var(--background-color);
  user-select: text;
  line-height: 1.5715;
}

html {
  height: 100%;
}

::placeholder {
  color: #88879b !important;
}

::-ms-input-placeholder {
  color: #88879b !important;
}

hr {
  box-sizing: content-box !important;
  height: 0 !important;
  overflow: visible !important;
  justify-content: center !important;
  align-self: center !important;
}

h1 {
  font-size: 30px !important;
}

h2 {
  font-size: 24px !important;
}

h3 {
  font-size: var(--bodysize) !important;
}

h4 {
  font-size: 14px !important;
}

h5 {
  font-size: 12px !important;
}

h6 {
  font-size: 10px !important;
}

a {
  color: var(--default-color) !important;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

a:hover,
a.active {
  color: var(--main-green-color) !important;
  text-decoration: none !important;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

.text-green {
  color: var(--main-green-color) !important;
  text-decoration: none !important;
}

.text-muted {
  color: var(--default-color);
}

.text-black {
  color: var(--black) !important;
}

.bg-success {
  background-color: var(--main-green-color) !important;
}

p {
  font-size: 15px;
}

.w-5 {
  width: 1.5rem !important;
}

.nav-link {
  text-transform: unset !important;
}

.card-border {
  border-radius: 6px !important;
}

.ibox-shadow,
.shadow {
  box-shadow: var(--box-shadow) !important;
}

.public-asset-badge {
  border-radius: 0px !important;
}

.badge-pill {
  border-radius: 10rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--main-green-color) !important;
  text-decoration: none;
  background-color: transparent !important;
}

.ReactModal__Overlay {
  z-index: 1050 !important;
}

/*autocomplete textbox */

.ui-menu {
  list-style: none;
  padding: 10px;
  margin: 0;
  display: block;
  width: 227px;
  z-index: 1020;
}

.ui-autocomplete {
  background: var(--white);
  border: 1px solid #e9e9f0;
}

.ui-autocomplete.source:hover {
  background-color: #f1f1f4 !important;
  color: #a7a6ab;
}

.ui-menu .ui-menu-item:hover {
  background: #f1f1f4 !important;
  cursor: pointer;
}

.ui-helper-hidden-accessible {
  display: none;
}

.ui-autocomplete .ui-menu-item .ui-state-active {
  background-color: #f1f1f4;
  color: #a7a6ab;
}

.btn-success {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
  border-color: var(--main-green-color) !important;
}

.btn-outline-success.active,
.btn-outline-success:hover {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
}

.btn-outline-success {
  color: var(--main-green-color) !important;
  border-color: var(--main-green-color) !important;
}

.btn-secondary:hover {
  color: var(--white) !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.btn-secondary,
.btn-secondary.disabled {
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14),
    0 3px 1px -2px rgba(169, 169, 169, 0.2),
    0 1px 5px 0 rgba(169, 169, 169, 0.12) !important;
  transition: 0.2s ease-in;
  background-color: #ffffff !important;
  color: #67757c !important;
  border-color: #b1b8bb !important;
}

.btn-facebook {
  color: var(--white) !important;
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}

.btn-twitter {
  color: var(--white) !important;
  background-color: #55acee !important;
  border-color: #55acee !important;
}

.btn-linkedin {
  color: var(--white) !important;
  background-color: #227bb6 !important;
  border-color: #227bb6 !important;
}

.btn {
  border-radius: 3px !important;
  font-size: inherit !important;
}

.btn-social-icon {
  position: relative !important;
  z-index: 1;
  display: inline-block;
  padding: 0 !important;
  overflow: hidden;
  vertical-align: middle !important;
  cursor: pointer;
  border-radius: .25rem !important;
  /* box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); */
  width: 30px !important;
  height: 30px !important;
}

.btn-social-icon i {
  display: inline-block !important;
  width: inherit !important;
  color: var(--white);
  text-align: center !important;
  font-size: 15px !important;
  line-height: 30px !important;
}

/* avtar */

.imagePreview {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-color: var(--white) !important;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 8px;
}

.client-avatar {
  width: 42px !important;
}

.client-avatar img {
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
}

/* custom readio-checkbox-label */

.form-select {
  display: block !important;
}

.form-check-input:checked {
  color: var(--white);
  border-color: var(--main-green-color) !important;
  background-color: var(--main-green-color) !important;
}

.checkboxtask {
  visibility: hidden;
}

.label-agree-term {
  border: 1px solid #ccc;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 4px 0.25em 0 -7px;
  padding: 0;
  width: 16px;
}

.checkboxtask:checked~label::before {
  color: var(--main-green-color) !important;
  content: "\2713" !important;
  text-align: center !important;
}

.error {
  font-size: 80%;
  color: #f5645f !important;
}

.cursor {
  cursor: pointer !important;
}

.nav-tabs>li>span {
  font-weight: 600;
  padding: 10px 20px 10px 25px;
  font-size: 15px;
}

/*range slider*/

output {
  position: absolute !important;
  top: 0px !important;
  display: none !important;
  width: 50px !important;
  height: 24px !important;
  border: 1px solid #e2e2e2 !important;
  background-color: var(--white) !important;
  border-radius: 3px !important;
  color: #777 !important;
  font-size: 0.8em !important;
  line-height: 24px !important;
  text-align: center !important;
}

input[type="range"]:active+output {
  display: block !important;
  transform: translateX(-50%) !important;
}

/*chart*/

.chart-data {
  left: 58%;
  top: 40%;
  position: absolute;
}

.chart-nodata {
  height: unset;
  left: unset;
  top: unset;
}

/* imagepreview asset */

.imagetitle {
  font-size: 0.9em;
  display: block;
  color: var(--white);
  background: rgba(69, 80, 91, 0.85) !important;
  transition: 0.2s background ease-in-out;
  padding: 5px;
  position: absolute;
}

.editimagetitle {
  bottom: -24px;
  width: 99%;
  left: 1px;
}

.addimagetitle {
  width: 94% !important;
  left: 5px !important;
  bottom: 4px !important;
}

.add-preview-images-zone {
  width: 100%;
  padding-top: 10px !important;
  position: relative;
  overflow: auto;
}

.add-preview-images-zone>.preview-image {
  width: 20%;
  object-fit: cover;
  object-position: center;
  position: relative;
  margin-right: 16px;
  float: left;
  margin-bottom: 1%;
}

.add-preview-images-zone>.preview-image>.image-zone,
.existing-images-zone>.existing-preview-image>.image-zone,
.update-preview-images-zone>.update-preview-image>.update-image-zone>img {
  width: 100%;
  height: 120px;
}

.add-preview-images-zone>.preview-image>.tools-edit-image {
  position: absolute;
  z-index: 100;
  color: var(--white);
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  display: none;
}

.add-preview-images-zone>.preview-image>.image-cancel {
  display: inline;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px 6px 3px;
  text-decoration: none;
  font-size: var(--bodysize);
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
}

.img-responsive {
  display: block;
  height: 114px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-radius: 5px;
}

.existing-images-zone {
  width: 100%;
  position: relative;
}

.existing-images-zone>.existing-preview-image {
  height: 90px;
  width: 18%;
  position: relative;
  margin-right: 8px;
  float: left;
  margin-bottom: 5%;
}

.existing-images-zone>.existing-preview-image>.image-zone>img {
  width: 100%;
  height: 120px;
}

.existing-images-zone>.existing-preview-image>.image-delete {
  display: inline;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 2px 6px 3px;
  text-decoration: none;
  font-size: var(--bodysize);
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
}

.image-radio {
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 4px solid transparent;
  margin-bottom: 0;
  outline: 0;
}

.image-radio input[type="radio"] {
  display: none;
}

.image-radio-checked .updateimagetitle {
  display: block !important;
}

.image-radio-checked .addimagetitle {
  display: block !important;
}

/* Loader */

.main-spinner-wrapper-fixed {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  z-index: 9999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: white;
}

.main-spinner-wrapper-absolute {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  z-index: 9999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: white;
}

.infinite-scroll {
  height: 0;
  width: 0;
  padding: 15px;
  border: 4px solid #ccc;
  border-right-color: var(--main-green-color);
  border-radius: 22px;
  animation: rotate 1.5s infinite linear;
  position: absolute;
  left: 50%;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.main-spinner-wrapper-fixed .donut,
.main-spinner-wrapper-absolute .donut {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 4px solid rgba(10, 157, 117, 0.1);
  border-top-color: var(--main-green-color);
  animation: 1.5s spin infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/*leaflet map */

.leaflet-touch .leaflet-bar a {
  padding: 0 !important;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 10px !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-popup-content {
  margin-top: 5px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.leaflet-popup-content-wrapper {
  padding: 0.5px !important;
  text-align: left !important;
  border-radius: 5px !important;
}

.markerPopupCustomStyle .leaflet-popup-content-wrapper,
.markerPopupCustomStyle .leaflet-popup-tip {
  background: unset !important;
  color: unset !important;
  box-shadow: unset !important;
}

.markerPopupCustomStyle .leaflet-popup-content {
  margin: 0 !important;
  padding: 0;
  background-color: var(--white);
  text-align: center;
  width: 200px;
  border-radius: 6px;
  box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
}

.marker-popup-details-image {
  float: left;
}

.marker-popup-details img {
  float: left;
  width: 90px;
  height: 70px;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.property-view-title {
  height: 1.5em !important;
  overflow: hidden !important;
  white-space: nowrap;
  width: 50px;
  text-overflow: ellipsis;
}

.asset-marker-title,
.agri-maker-title {
  display: inline-block;
  font-size: 13px;
  color: var(--black);
  padding: 10px;
  font-weight: 600;
}

.agri-maker-title {
  display: flex;
  justify-content: center;
}

.marker-popup-details-title {
  display: inline-block;
  font-size: 15px;
  color: var(--black);
  padding: 0 0 0 10px;
  margin-top: 5px;
  width: 168px;
  font-weight: 600;
}

.marker-popup-details-price {
  font-size: 15px !important;
  color: var(--main-green-color);
  font-weight: 600;
  display: inline-block;
  padding: 0 0 0 10px;
}

.news-notice-marker-popup-details-title {
  display: inline-block;
  font-size: 15px;
  color: var(--black);
  padding: 0 0 0 10px;
  font-weight: 600;
}

#marker-popup-details-sqft {
  font-size: 13px !important;
  background-repeat: no-repeat;
  margin-left: 10px;
  padding-left: 0;
  color: var(--default-color);
  background-position: 0 0;
  line-height: 22px;
  margin-top: 0;
}

.marker-popup-details-sqft {
  padding-left: 0;
  font-weight: 700;
  display: inline-block;
  width: 60%;
}

.markerPopupCustomStyle.leaflet-popup {
  margin-bottom: 15px !important;
}

/* title eclips */

.eclips-deal-task-list {
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
}

.eclips-asset-title {
  max-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
}

.bg-holder {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 80vh;
}

.property-bg-holder {
  background: url(../assets/img/PropertyBackgroundImage.jpg);
}

.bg-overlay-black:before {
  background: rgba(0, 0, 0, 0.3) !important;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
}

.bg-holder-image {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
  background: url(../assets/img/BackgroundImage.jpg);
}

.featured_panel {
  position: absolute;
  overflow: hidden;
  border-top-left-radius: 10px;
  font-weight: bold;
  color: #ffffff;
  line-height: 41px;
  z-index: 1;
}

/* contatc-company */

.client-link {
  font-weight: 600;
  color: var(--default-color) !important;
}

.client-link:hover {
  color: inherit;
}

.contact-type {
  width: 20px !important;
  color: #c1c3c4 !important;
}

/* input group addon */

.auth-input-group-text {
  font-size: 0.9rem !important;
}

.input-group-text {
  background-color: var(--white);
}

/*comment section*/

.comment-title {
  color: #888b8c !important;
  font-size: 20px !important;
}

.comment-form {
  width: 100%;
  box-sizing: border-box;
  background: #f4f4f4;
  border: solid 1px #ddd;
  border-radius: 0.25rem;
}

#commentInput textarea {
  display: block;
  padding: 10px;
  margin-right: 5px;
  height: 100px;
  resize: none;
  border-radius: 10px 10px 0 0;
  white-space: pre-wrap;
  border: 0;
  color: #333;
  font: 12px/18px var(--pfamily);
  width: 100%;
  max-width: 100%;
  outline: none;
}

.saveComment-btn {
  background: var(--main-green-color);
  color: var(--white);
  width: 84px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  /* float: right; */
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.errwordcount {
  color: #f6645f;
  font-size: 12px;
  line-height: 28px;
  padding: 5px 5px 0 9px;
  text-align: left;
  float: left;
  word-break: break-all;
}

/* badge */

.badge {
  color: white;
  font-family: var(--pboldfamily);
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
  white-space: nowrap;
}

.assetlistingobject,
.usertradelistingobject {
  width: 3rem !important;
  padding: 5px !important;
}

.asset-title span {
  font-size: 14px;
  color: var(--default-color);
  font-weight: 600;
}

.task-checkbox {
  width: 40px;
}

.dealdescription {
  width: 70vh;
}

.hyperlink {
  color: var(--default-color) !important;
  text-decoration: none;
  cursor: pointer;
}

.hyperlink:hover {
  color: var(--main-green-color) !important;
  text-decoration: none !important;
  cursor: pointer;
}

.card-title {
  font-family: var(--pfamily) !important;
  font-size: var(--bodysize) !important;
  line-height: 1.5rem !important;
  color: var(--default-color);
  height: 3em;
  overflow: hidden;
  position: relative;
}

/* favorite icon */

.star-icon {
  position: absolute;
  right: 0;
  color: var(--default-color);
  z-index: 1;
  opacity: 2;
}

.icon-star {
  color: #f1ae03;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f1ae03;
}

.fav-icon>i {
  font-size: 20px !important;
  width: 25px;
  height: 25px;
  font-weight: 700;
}

/*share*/

.share-info {
  position: relative !important;
  right: 10px;
}

.share-info .sharebtn i {
  transition-duration: 800ms;
}

.share-info .sharebtn:hover {
  color: var(--default-color);
}

.share-info .all-share-btn {
  position: absolute;
  z-index: 20;
  top: -3px;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  transition-duration: 800ms;
}

.share-info:hover .sharebtn i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.share-info:hover .all-share-btn {
  opacity: 1;
  visibility: visible;
  right: 14px;
}

.description {
  white-space: pre-wrap;
}

.tab-content {
  background: var(--white);
  border: 1px solid #c8ced3;
}

.is-task-active {
  color: var(--main-green-color) !important;
}

.no-horizontal-overflow {
  overflow-x: hidden !important;
}

/*prperty css*/

.properties_item {
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.properties_item .pp_content h4 {
  font-size: var(--bodysize);
  font-weight: 500;
  color: #222222;
  margin-bottom: 15px;
}

.pp_img img {
  height: 150px !important;
  width: 100% !important;
  object-fit: cover !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/*file upload asset*/

.file-drag {
  cursor: default;
  background-color: #eceff1;
  color: #607d8b;
  background-image: linear-gradient(to top,
      currentColor 10px,
      var(--white) 10px),
    linear-gradient(to right, currentColor 10px, var(--white) 10px),
    linear-gradient(to bottom, currentColor 10px, var(--white) 10px),
    linear-gradient(to left, currentColor 10px, var(--white) 10px);
  background-size: 1px 20px, 20px 1px, 1px 20px, 20px 1px;
  background-repeat: repeat-y, repeat-x, repeat-y, repeat-x;
  animation: background-position 1s linear infinite;
  will-change: background-position;
}

@keyframes background-position {
  from {
    background-position: 0% 0px, 0px 100%, 100% 0px, 0px 0%;
  }

  to {
    background-position: 0% 40px, 40px 100%, 100% -40px, -40px 0%;
  }
}

.input-wrapper .input {
  position: absolute;
  top: 14px;
  opacity: 0;
  left: 285px;
  cursor: pointer;
}

@keyframes load-item {
  from {
    transform: rotate(0deg) translateY(20px);
  }

  to {
    transform: rotate(360deg) translateY(20px);
  }
}

.button-upload {
  font-size: 0.8em;
  margin-right: 0.5em;
  padding: 0.35em 0.5em;
  color: currentColor;
  border: 1px solid !important;
  cursor: pointer;
  background: transparent;
  color: #607d8b !important;
  white-space: nowrap;
  font-weight: lighter;
  text-transform: uppercase;
}

.input-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: #212121;
}

.input-cover {
  padding: 1em;
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 2px;
  justify-content: center;
}

.icon-star-o {
  color: white !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--default-color);
}

.icon-star {
  color: #f1ae03;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f1ae03;
}

.col-form-label {
  font-size: 15px !important;
}

#searchAreaButton {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  border-width: 1px;
  border-color: rgb(0, 0, 0);
  font-size: 12px;
  border-radius: 4px;
}

#searchAreaButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

/*Property details ui & asset details ui*/

.asset-details-star {
  position: absolute;
  top: 5px;
  z-index: 98;
  font-size: 14px;
}

.asset-share .sharebtn {
  position: relative;
  width: 45px;
  height: 25px;
  line-height: 25px;
  color: var(--main-green-color);
  text-align: center;
  font-size: var(--bodysize);
  display: block;
  border-radius: 12px;
}

.asset-share {
  position: relative;
}

.asset-share .sharebtn i {
  -webkit-transition-duration: 800ms;
  -o-transition-duration: 800ms;
  transition-duration: 800ms;
}

.asset-share .sharebtn:hover {
  color: var(--default-color);
}

.asset-share .all-share-btn {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 800ms;
  -o-transition-duration: 800ms;
  transition-duration: 800ms;
}

.asset-share:hover .sharebtn i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.asset-share:hover .all-share-btn {
  opacity: 1;
  visibility: visible;
  right: 25px;
}

.header-app-content {
  padding: 20px 0 0 0;
  position: relative !important;
  z-index: 2;
  min-height: calc(100vh - 50px) !important;
  background-color: var(--white);
}

.public-page-app-content {
  padding-top: 70px;
}

.side-app {
  padding: 62px 17px 17px 17px;
  background-color: var(--background-color);
  min-height: 100vh !important;
}

#noticesmodalid .modal-title,
#newsmodalid .modal-title,
#assetmodalid .modal-title,
#contactmodalid .modal-title,
#companymodalid .modal-title {
  width: 100% !important;
}

.lead {
  font-size: 17px !important;
  font-weight: 300;
}

.input-lg {
  height: 50px !important;
  padding: 10px 16px;
  font-size: var(--bodysize);
  line-height: 1.3333333;
  border-radius: 3px !important;
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
}

.input-lg::placeholder {
  color: var(--default-color) !important;
}

.input-lg::-ms-input-placeholder {
  color: var(--default-color) !important;
}

.location-gps {
  cursor: pointer !important;
  height: 50px !important;
  position: absolute !important;
  right: 25px !important;
  text-align: right;
  top: 16px !important;
  width: 25px !important;
  color: var(--default-color) !important;
  font-size: 20px !important;
}

.margin-right {
  margin-right: 4px;
}

.editasset-map,
.add-asset-map {
  height: 300px !important;
  z-index: 1 !important;
}

.full_form_image {
  margin-top: 10px;
  margin-left: 5px;
}

/* add asset */

.stepwizard-step p,
.tradestepwizard-step p {
  margin-top: 0px;
  color: #666;
}

.stepwizard {
  position: relative;
}

.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  color: #bbb !important;
  pointer-events: none;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 99.4%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
  left: 1;
}

.stepwizard-step,
.tradestepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.add-btn-circle {
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  padding: 6px 0 !important;
  font-size: 12px !important;
  line-height: 1.428571429 !important;
  border-radius: 15px !important;
}

.add-btn-default {
  color: #333 !important;
  background-color: var(--white) !important;
  border-color: #ccc !important;
  pointer-events: none !important;
}

.add-btn-success {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
  border-color: var(--white) !important;
  pointer-events: none !important;
}

.add-btn-success.active {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
  border-color: var(--white) !important;
  pointer-events: none !important;
}

.trade-title-row {
  color: var(--black);
  font-size: 17px;
  line-height: 1.6em;
  font-weight: 600;
  font-family: var(--pboldfamily);
}

.trade_explain {
  font-size: 14px;
  line-height: 1.4em;
}

.asset-page label,
.deal-page label,
.trade-page label {
  margin: 0px 0px 0px 0px;
  width: auto;
  font-weight: 500;
  font-size: 15px;
  color: #353535;
}

.asset-col-label,
.deal-col-label,
.trade-col-label {
  padding-right: 15px;
  padding-left: 0px;
}

.asset-page,
.deal-page,
.trade-page {
  margin: 0px 0px;
  margin-bottom: 26px;
  line-height: 22px;
}

.setup-content .panel-body {
  padding: 20px;
  background-color: var(--white);
}

.wizard {
  margin: 20px auto;
  background: var(--white);
}

.wizard .nav-tabs {
  position: relative;
  margin: 40px auto;
  margin-bottom: 0;
  border-bottom-color: #e0e0e0;
}

.wizard>div.wizard-inner {
  position: relative;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

.wizard .nav-tabs>li {
  width: 25%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #5bc0de;
  transition: 0.1s ease-in-out;
}

.wizard li.active:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: #5bc0de;
}

.wizard .nav-tabs>li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.wizard .nav-tabs>li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 50px;
}

.wizard h3 {
  margin-top: 0;
}

/*auth modal*/

.popup-form>.div-form {
  float: left;
  width: 100%;
  padding: 0px 0;
  border-bottom: 1px solid #dfe0e5;
  padding-bottom: 22px;
  margin-bottom: 20px;
}

.popup .input-field>span {
  font-size: 13px;
  margin-top: 2px;
  padding-left: 8px;
  color: #9b9ca0;
}

.popup-form .form-field {
  margin-bottom: 10px;
}

.popup-form {
  float: left;
  width: 100%;
  padding: 0 15px 5px 15px;
}

.form-cp {
  float: left;
  width: 100%;
  margin-bottom: 18px;
}

.popup .input-field .custom-control-label::before {
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #9b9ca0 !important;
  border-radius: 2px !important;
  top: 3.5px !important;
  display: inline-block !important;
}

.popup .input-field .custom-control-label::after {
  top: 3.5px !important;
}

.form-cp .form-field {
  float: left;
}

.form-cp>.forgotPasswordBtn {
  display: inline-block;
  font-size: 13px;
  color: var(--default-color) !important;
}

.form-cp>.forgotPasswordBtn:hover {
  color: var(--main-green-color) !important;
}

.popup-form .form-field input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: var(--text) !important;
  display: block;
  width: 100%;
  font-size: 0.9rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.popup-form .form-field input {
  padding: 6px 12px;
}

.input-field>input[type="checkbox"] {
  display: none;
}

.popup-form .btn2 {
  background: var(--main-green-color);
  border-color: var(--main-green-color);
  width: 25%;
  color: var(--white);
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5 !important;
  z-index: 1;
  overflow: hidden;
  font-weight: 400;
  position: relative;
}

/*ibox*/

.ibox {
  clear: both;
  margin-top: 0;
  padding: 0;
  background-color: var(--white);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 12px;
}

.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 15px 90px 8px 15px;
  position: relative;
  clear: both;
}

.ibox-title h1 {
  display: inline-block;
  font-size: 14px !important;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: none;
  font-weight: 700;
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  text-align: right;
}

.ibox-content {
  padding: 12px;
  color: inherit;
}

.ibox-footer {
  color: inherit;
  font-size: 90%;
  padding: 10px 10px 0 10px;
}

/*property view*/

.entry-prop {
  color: var(--black) !important;
  margin-bottom: 0;
  float: left;
  width: auto;
  max-width: 75%;
}

.entry-title {
  letter-spacing: -0.01em;
  font-weight: 600;
}

.price_area {
  color: #000000;
  font-size: var(--bodysize);
  float: right;
  font-weight: 600;
}

.single-content {
  padding: 0;
  font-size: 14px;
  line-height: 1.65em;
  float: left;
  width: 100%;
}

.notice_area {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  position: relative;
  margin-top: 12px;
}

.property_categs {
  font-size: 15px;
  margin-top: 14px;
  margin-bottom: 3px;
  font-weight: 600;
}

.adres_area {
  clear: both;
  font-size: 15px;
  width: 80%;
  font-weight: 600;
}

#add_favorites {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 13px;
  cursor: pointer;
}

.prop_social {
  position: absolute;
  top: 43px;
  right: 0;
}

.post-carusel {
  margin-bottom: 15px;
}

.no_views {
  float: left;
  font-size: 14px;
}

.prop_social i {
  font-size: 14px;
}

/* introduction ui */

#introductionmodal .img-div {
  height: 300px;
}

#introductionmodal .img-div-login {
  width: 100% !important;
  height: 190px !important;
}

#introductionmodal img {
  width: 100%;
  height: 100%;
}

.contact-company-photo {
  max-height: 180px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
}

.stream-small {
  margin: 10px 0;
}

/*web tab css*/

.weblist-tabs-container .nav-tabs .nav-link.active {
  color: #495057 !important;
  background-color: var(--white) !important;
  border-color: #dee2e6 #dee2e6 var(--white) !important;
}

.weblist-tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec;
}

.weblist-tabs-container .nav-tabs>li {
  float: left;
  margin-bottom: -1px !important;
}

.weblist-tabs-container .tab-content {
  border: unset;
  background: unset !important;
}

.weblist-tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec !important;
}

.nav-tabs .nav-link:hover,
.weblist-tabs-container .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link:not(.active):focus,
.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent !important;
}

.weblist-tabs-container .tab-pane .panel-body {
  background: var(--white);
  border: 1px solid #e7eaec;
  padding: 20px !important;
  position: relative;
  border-top: 0 !important;
}

.nav-tabs .nav-link:hover,
.weblist-tabs-container .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link:not(.active):focus,
.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent !important;
}

.weblist-tabs-container .tab-pane .panel-body {
  background: var(--white);
  border: 1px solid #e7eaec;
  padding: 20px !important;
  position: relative;
  border-top: 0 !important;
}

.table-list tr td {
  border-top: 0 !important;
  padding: 5px;
}

.small-font-size {
  font-size: 11px !important;
}

.markdown-content pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}

.map-view {
  z-index: 1;
  height: 300px;
}

.map-header {
  height: 300px !important;
}

.new-notice-swiper-img {
  height: 297px !important;
}

.carousel-item img {
  object-fit: cover;
  height: 300px !important;
}

.input-group>.input-group-text>.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


/*markdown */

.markdown-content h2 {
  font-size: 17px !important;
  font-weight: 700;
}

.markdown-content h3 {
  font-size: var(--bodysize) !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
}

.markdown-content h4 {
  font-size: 15px !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
}

.markdown-content h5 {
  font-size: 14px !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
}

.markdown-content h6 {
  font-size: 13px !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
}

/*google translate*/
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.link-hyperlink {
  color: var(--main-green-color) !important;
  cursor: pointer;
}

.link-hyperlink:hover {
  color: var(--main-green-color) !important;
  text-decoration: underline;
  cursor: pointer;
}

/*about*/

.about-ibox {
  text-align: center;
  padding: 3em 2em 3em 2em;
  background: var(--white);
  box-shadow: 0px 8px 20px 0px rgb(0 0 0 / 8%);
  border-radius: 20px 20px 20px 20px;
  margin: 10px 10px 10px 10px;
}

.about-ibox .icon {
  margin-bottom: 15px !important;
}

.about-ibox .icon i {
  font-size: 30px !important;
}

.about-ibox p {
  font-size: 15px !important;
  color: #777777;
  line-height: 26px;
  margin-bottom: 15px;
  height: 75px;
}

.btn-about {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 6px;
  transition: 0.5s;
  line-height: 1;
  border: thin solid var(--main-green-color) !important;
  color: var(--main-green-color) !important;
}

.btn-about:hover {
  border: thin solid #6ec083 !important;
  color: #6ec083 !important;
}

.color-headline {
  color: #777088 !important;
  fill: #777088 !important;
  font-size: 1.2rem !important;
}

.static-page-wrapper {
  background-color: #f9f9f9;
}

.section-head {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.asset-inner {
  position: relative;
}

.box-icon {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  font-size: 45px;
  background-color: var(--white);
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

.about-box-content h5.title {
  font-size: 1.125rem !important;
  color: #333;
}

.about-box-content {
  padding-top: 1rem;
}

.about-box-content .title {
  margin-bottom: 0.6875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}

#policyDiv a,
.terms-wrapper a {
  color: var(--main-green-color) !important;
  font-weight: 500 !important;
}

#policyDiv a:hover,
.terms-wrapper a:hover {
  cursor: pointer !important;
  text-decoration: initial !important;
  color: #008329 !important;
}

#aboutusDiv p {
  line-height: 21px;
  margin-bottom: 21px;
  color: var(--black) !important;
  font-size: 15px !important;
}

.terms-wrapper h4 {
  font-size: 1.125rem !important;
  margin-bottom: 15px;
  color: #333 !important;
}

.terms-wrapper p,
.police-wrapper p,
.terms-wrapper ol {
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.static-page-img {
  object-fit: cover;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  max-height: 45vh;
}

.static-page-img-lg {
  object-fit: cover;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  max-height: 60vh;
}

.services-img {
  height: 60px;
  width: 60px;
  border-radius: 10px;
}

.services-expertise-img {
  height: 80px;
  width: 80px;
  border-radius: 10px;
}

.services-div {
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  width: 20%;
  text-align: center;
  padding: 1% 2% !important;
  margin-bottom: 13px;
}

.services-expertise-div {
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  width: 14.2%;
  text-align: center;
  padding: 1% 2% !important;
  margin-bottom: 13px;
}

.count-text-color {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: rgb(147, 147, 147);
  margin-left: 5px;
}

.contactus-comment {
  height: 100px !important;
  padding: 15px !important;
}

.appFooter {
  background-color: #6e9d753b !important;
}

.footer-company-logo {
  height: 4rem;
  line-height: 3rem;
  width: auto;
}

#footerDiv .navlink:hover {
  text-decoration: underline !important;
  color: var(--default-color) !important;
  cursor: pointer;
}

/* .total-record {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  padding: 0;
  bottom: 5px;
} */

.nav-tabs {
  border-bottom: unset !important;
}

.full-paragraph-title-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 25px !important;
  line-height: 1.4 !important;
  font-weight: 700;
  color: rgb(50, 51, 56);
  font-family: var(--pboldfamily);
}

.paragraph-body {
  color: rgb(50, 51, 56);
  font-size: 18px !important;
  line-height: 32px !important;
}

.paragraph-body-padding-right {
  padding: 0 60px 0 0 !important;
}

.paragraph-body-padding-left {
  padding: 0 0 0 60px !important;
}

.labelTabCreate {
  color: #212529 !important;
  font-size: 14px !important;
  margin-bottom: 5px !important;
  line-height: 1.5;
}

.card-header img {
  border: 5px solid white !important;
}

.testimonial-outside {
  position: absolute;
  left: -11%;
}

.testimonial-card {
  border-radius: 20px !important;
  box-shadow: var(--box-shadow) !important;
  width: 20rem !important;
  margin-left: 25px !important;
  border: 0 !important;
  margin-bottom: 20px;
}

.testimonial-card-footer {
  border-radius: 20px !important;
}

.testimonial-bold {
  font-weight: 500 !important;
  color: var(--black) !important;
}

.margin-left {
  margin-left: 30px !important;
}

/*top news notice property*/

.explorPageBody {
  min-height: 360px;
  background-color: #555;
}

.explorPageBody,
.explorPageCount {
  height: 100%;
}

.explorPageImage {
  background-position: center;
  background-size: cover;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
}

.propertyExplorImage {
  background-image: url(../assets/img/PropertyBackgroundImage.jpg);
}

.homeExplorImage {
  background-image: url(../assets/img/BackgroundImage.jpg);
}

.sbox {
  top: 40vh !important;
  position: relative;
  margin: 0;
  z-index: 2;
}

.home-sbox {
  top: 35.5vh !important;
  position: relative;
  margin: 0;
  z-index: 2;
}

.bottom_row {
  display: block;
  top: calc(5vh + 15.0625rem) !important;
  overflow: hidden;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.bottom_row .scroll_cont {
  outline: none;
  width: calc(100% + 20px) !important;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100% !important;
  bottom: 0;
  position: absolute;
}

.vs_cont {
  transition: all 0.2s;
  top: calc(100% - 5.9375rem);
  position: absolute;
  width: 100%;
}

.vs_cont .vs {
  width: 100%;
}

.vs_cont .moduleCont {
  background-color: var(--white);
}

.sbox.fix {
  top: 120px !important;
  transform: none !important;
}

.home-sbox.fix {
  top: 85px !important;
  transform: none !important;
}

.servicesModal .modal-body {
  padding: 20px 30px 30px 30px !important;
}

/*header*/

.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  position: relative;
  text-align: center;
  color: var(--white);
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.brround {
  border-radius: 50%;
}

.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
}

.app-header1 .header-brand .header-brand-img {
  margin-right: 0;
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 40px;
  width: auto;
  margin-bottom: 5px;
}

.user-img {
  margin-top: 2px;
}

.main-search-form {
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: 20px;
}

.search-type {
  display: inline-block;
  padding-bottom: 8px;
  position: relative;
}

.search-type label:hover,
.search-type label.active {
  color: var(--main-green-color);
  text-shadow: rgb(0, 0, 0) 0px 0px 5px;
}

.search-type label {
  color: #ffff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 9px 5px;
  margin: 0 10px 0 0;
  transition: all 0.2s;
  font-size: 18px;
  text-shadow: rgb(0, 0, 0) 0px 0px 5px;
}

.search-type label:hover {
  color: var(--main-green-color);
  text-shadow: rgb(0, 0, 0) 0px 0px 5px;
}

.search-type-arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--white) !important;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-search-box {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}

.search-type input[type="radio"] {
  display: none;
}

.horizontalMenu>.horizontalMenu-list {
  display: block;
  padding: 0;
}

.horizontalMenu>.horizontalMenu-list>li {
  display: block;
  float: left;
  margin-right: 25px;
  /* font-size: 16px; */
}

.horizontalMenu>.horizontalMenu-list>li>a:not(:hover),
.horizontalMenu>.horizontalMenu-list>li>strong:not(:hover) {
  /* padding: 18px 15px; */
  font-weight: 600 !important;
  color: #666 !important;
  display: block;
  font-family: var(--pboldfamily);
  font-size: 16px !important;
}

.horizontalMenu>.horizontalMenu-list>li>a.active,
.horizontalMenu>.horizontalMenu-list>li>a:hover {
  color: var(--main-green-color) !important;
  font-size: 16px !important;
  display: block;
  font-weight: 600 !important;
  font-family: var(--pboldfamily);
}

.horizontalMenu.right-nav>.horizontalMenu-list>li>a,
.horizontalMenu.right-nav>.horizontalMenu-list>li>a.hover {
  color: #222 !important;
  font-size: 14px !important;
}

.dropdown-item .fa,
.dropdown-item .icon {
  vertical-align: middle !important;
}

.dropdown-icon {
  color: var(--default-color);
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  width: 1em;
  display: inline-block;
  text-align: center;
  vertical-align: -1px;
  font-size: 14px;
}

.dropdown-menu.show {
  display: block;
  box-shadow: 0 5px 25px rgba(39, 55, 76, 0.2);
}

.form-inline .nav-search .btn {
  border: 1px solid #dee2e6;
  background-color: #dee2e6;
}

.btn-outline-gray {
  /* border: 1px solid var(--main-green-color); */
  cursor: pointer;
  padding: 8px 16px !important;
  display: inline-block;
  outline: none;
  font-size: 14px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  background: #f3f3f3 !important;
  color: var(--black) !important;
  font-family: var(--pboldfamily);
}

/*------ Backtop ------*/

#back-to-top {
  background: #a8a8a8;
}

.back-to-top-mobile {
  bottom: 83px !important;
  right: 5px !important;
}

#back-to-top {
  color: var(--white) !important;
  position: fixed;
  bottom: 58px;
  right: 20px;
  z-index: 99;
  display: none;
  text-align: center;
  border-radius: 3px;
  z-index: 10000;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
}

#back-to-top i {
  padding-top: 15px;
  font-size: var(--bodysize);
}

.header {
  width: 100%;
  z-index: 100;
  background: var(--white);
  position: fixed;
  top: 0;
}

.nav-search .form-control.header-search {
  background: var(--white);
  border: 1px solid #dee2e6;
  border-radius: 6px;
  color: #24273c;
  font-size: 14px;
  margin-right: -31px !important;
  width: 300px;
  padding: 12px;
}

.nav-search {
  padding-top: 13px !important;
}

.navbar-nav .input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 2.5rem;
  pointer-events: none;
  color: var(--white);
}

.form-control::-webkit-input-placeholder {
  color: #a7b4c9;
  font-size: 14px;
}

.nav-link.navsearch-icon {
  color: #374254;
}

#collapseApplicationDevelopment.collapse:not(.show),
#collapseGeospatialInformationService.collapse:not(.show),
#collapseDigitalTransformation.collapse:not(.show) {
  display: block;
  height: 3.5rem;
  overflow: hidden;
}

#collapseApplicationDevelopment.collapsing,
#collapseGeospatialInformationService.collapsing,
#collapseDigitalTransformation.collapsing {
  height: 3.5rem;
}

.ServicesMainDiv #btnApplicationDevelopment.collapsed::after,
.ServicesMainDiv #btnGeospatialInformationService.collapsed::after,
.ServicesMainDiv #btnDigitalTransformation.collapsed::after {
  content: "Show More";
}

.ServicesMainDiv #btnApplicationDevelopment:not(.collapsed)::after,
.ServicesMainDiv #btnGeospatialInformationService:not(.collapsed)::after,
.ServicesMainDiv #btnDigitalTransformation:not(.collapsed)::after {
  content: "Show Less";
}

.contactus-map {
  height: 350px;
}

.userNameText {
  padding: 9px 9px;
  font-weight: 600;
  color: #666666;
  font-size: 15px;
}

.img-border {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.deal-list {
  border-bottom: 1px solid #eee;
  font-size: 25px !important;
  margin: 0;
  width: 100%;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 15px;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

/* feeadbackcss */

.feedback-rating-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  display: none !important;
}

.rating-card-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 65px;
  height: 60px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  opacity: 0.5;
}

.feedback-rating-selector input:checked+.rating-card-cc {
  opacity: 0.9 !important;
}

.feedback-rating-selector input:active+.rating-card-cc {
  opacity: 0.9 !important;
}

.bad {
  background-image: url("../assets/img/FeedBackSad.jpg");
}

.average {
  background-image: url("../assets/img/FeedBackAverage.jpg");
}

.feedbackgood {
  background-image: url("../assets/img/FeedBackGood.jpg") !important;
}

.best {
  background-image: url("../assets/img/FeedBackBest.jpg");
}

.excellent {
  background-image: url("../assets/img/FeedBackExcellent.jpg");
}

/* PasswordStrengthMeter */

.change-password-strength-meter-label {
  font-size: 14px !important;
  position: absolute;
  right: 38px;
  bottom: 162px;
}

.change-password-strength-meter-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100% !important;
  height: 4px;
}

.signup-password-strength-meter-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100% !important;
  height: 4px;
  position: absolute;
}

.signup-password-strength-meter-label {
  font-size: 14px !important;
  position: absolute;
  right: 4px;
  top: 11px;
}

progress {
  vertical-align: top !important;
}

.weak::-webkit-progress-value {
  background: #f25f5c;
}

.okay::-webkit-progress-value {
  background: #ffe066;
}

.good::-webkit-progress-value {
  background: #247ba0;
}

.strong::-webkit-progress-value {
  background: #70c1b3;
}

.weak {
  color: #f25f5c;
}

.okay {
  color: #ffe066;
}

.good {
  color: #247ba0;
}

.strong {
  color: #70c1b3;
}

/* TOOGLE SWICH */

.onoffswitch {
  position: relative;
  width: 54px;
  --webkit-user-select: none;
  --moz-user-select: none;
  --ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--main-green-color);
  border-radius: 3px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 16px;
  padding: 0;
  line-height: 16px;
  font-size: 10px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "YES";
  padding-left: 7px;
  background-color: var(--main-green-color);
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "NO";
  padding-right: 7px;
  background-color: #ffffff;
  color: #919191;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0;
  background: #ffffff;
  border: 2px solid var(--main-green-color);
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0;
}

.platform-div-padding {
  padding: 0 20px;
}

.platformDiv .main-img {
  height: 200;
}

.card h5 {
  margin-bottom: 0;
  font-size: 1.25rem !important;
  font-weight: 700;
  line-height: 1;
}

.card .card-header {
  background-color: var(--white);
  padding: 30px;
  border-bottom: 1px solid #ced4da;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card .card-body {
  padding: 30px;
  background-color: transparent;
}

.rounded-circle {
  border: 1px solid #ced4da;
}

.image-rounded-circle {
  border-radius: 50% !important;
}

.table thead th {
  font-weight: 500;
  letter-spacing: 0.05em;
}

.table.custom-table>thead>tr>th {
  color: #8c8989 !important;
}

.table {
  color: var(--default-color) !important;
}

.table.custom-table {
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.footer-title {
  font-size: 18px !important;
}

/* .avtar-zoom {
  transition: transform 0.2s;
}

.avtar-zoom:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
} */

/*pagination*/

.pagination {
  margin-bottom: 0;
}

.page-link {
  color: #353535 !important;
  border: unset !important;
  border-radius: 0.25rem;
  margin: 0 2px 5px;
  padding: 6px 13px !important;
  z-index: 1;
  font-size: 15px;
  background: var(--white);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.page-link:hover {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
}

.table-list-search .form-control {
  padding-left: 2.3rem !important;
}

.table-list-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  line-height: 2.3rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.dealstage {
  font-weight: 700 !important;
  width: 6rem !important;
  padding: 5px !important;
  font-size: 67% !important;
}

.assetobject {
  font-weight: 100 !important;
  width: 5rem !important;
  padding: 7px !important;
  font-size: 70% !important;
  color: var(--white);
}

.ui-autocomplete {
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cursor-default {
  cursor: unset !important;
}

/* .trade-title, */
.commodity-title {
  font-weight: 500 !important;
  margin: 0 !important;
  color: var(--main-green-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trade-details-default-img img,
.userTradeDetails-default-img img {
  height: 300px !important;
}

/* .trade-details h2, */
.userTradeDetails h2 {
  color: #333;
  font-size: 24px;
  font-weight: 100;
}

/* .trade-details-price, */
.userTrade-details-price,
.trade-details-date,
.userTrade-details-date {
  font-size: 17px;
  font-weight: 100;
  margin: 0 0 8px;
  color: #666 !important;
}

.trade-details-hr,
.userTrade-details-hr,
.publicmarket-details-hr {
  border-top: 1px solid #dce0e0;
  margin: 0.75rem 0;
}

.trade-details-map {
  height: 290px;
  z-index: 1;
}

.userTrade-details-map {
  height: 230px;
  z-index: 1;
}

.trade-list-map,
.commodity-list-map,
.notice-list-map,
.news-list-map,
.store-list-map {
  height: 350px;
  margin-bottom: 10px;
}

.trade-carousel-img {
  overflow: hidden;
  border-radius: 6px;
}

.commodity-details-img {
  height: 300px;
  object-position: 50% 50%;
}

.commodity-details-map {
  height: 270px;
}

#tradeCheckbox .custom-control-label::before,
#tradeCheckbox .custom-control-label::after {
  top: 0.12rem;
}

.publicmarketDetails h2 {
  color: #333;
  font-size: 20px !important;
  font-weight: 100;
}

.publicmarket-contact-details {
  color: #737882;
  padding-right: 25px;
  margin-bottom: 12px;
}

.publicmarket-contact-details>span {
  font-size: 18px;
  float: left;
  color: var(--main-green-color);
}

.publicmarket-contact-details p {
  margin: 0 0 0 34px;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.55rem rgba(0, 0, 0, 0.075) !important;
}

.public-matket-details-map {
  height: 222px;
  z-index: 1;
}

.apmc-title {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin: 0 !important;
  color: var(--black);
  margin-bottom: 12px !important;
}

.apmc-address {
  color: #666 !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.25px;
}

.padding-top-1 {
  padding-top: 1px;
}

.div-position-sticky {
  position: sticky;
  top: 80px;
  margin-bottom: 37px;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.trade-tag {
  position: absolute;
  /* width: 19%; */
  top: 7px;
  left: 5px;
  z-index: 20;
  font-size: 11px !important;
  font-weight: 500 !important;
  padding: 5px 6px !important;
  line-height: 11px !important;
  margin: 0;
}

.background-side-app {
  background-color: var(--background-color) !important;
}

.badge-default-colour {
  padding: 5px 7px;
  font-weight: 500;
  font-size: 12px;
}

.market-dropdown {
  min-width: 14rem !important;
}

.description-font {
  line-height: 20px;
  color: #535353;
  font-weight: 500;
}

.medium-font-size {
  font-size: 14px !important;
}

.chart-img {
  width: 100%;
  height: 430px;
  border-radius: 10px;
}

#userTradeCheckBox .custom-control-label::before,
#userTradeCheckBox .custom-control-label::after {
  top: -1;
}

#newsNoticeTab .weblist-tabs-container .tab-pane .panel-body {
  padding: 20px 5px 20px 5px !important;
}

.payment-type .custom-control-label::before {
  top: 0.1rem !important;
}

.payment-type .custom-control-label::after {
  top: 0.1rem !important;
}

.payment-badge {
  font-size: 11px !important;
  letter-spacing: 0.8px;
  background: #6e9d753b;
  border-radius: 3px;
  border: 1px solid var(--main-green-color);
  color: var(--main-green-color);
  padding: 5px 14px 3px !important;
  margin-bottom: 0.5rem !important;
  white-space: unset !important;
}

/*trade deatils-commodity details*/

.w-110px {
  width: 120px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-heading {
  color: #333 !important;
}

.w-130px {
  width: 130px !important;
}

.form-select,
.form-control,
.input-group-text {
  font-size: 15px;
  font-weight: 400 !important;
  color: #495057;
  border-color: rgba(72, 94, 144, 0.15);
  background-color: transparent;
  padding: 0.5rem 0.9rem !important;
  height: auto;
}

.input-group-text {
  border-color: rgba(72, 94, 144, 0.15);
  background-color: transparent;
}

.image-border {
  border: 1px solid #e5e5e5;
}

.trade-date {
  color: #9c9c9c !important;
  font-size: 13px;
}

.favouritePageTab .weblist-tabs-container .tab-pane .panel-body {
  padding: 0.5rem !important;
}

.FilterTags-Tag {
  box-shadow: 0 0 0 1px #dce0e0;
  border-radius: 4px;
  /* background: #f8f7f5; */
  background: var(--white);
  margin: 0 0.5rem 0.5rem 0;
  padding: 5px 1.5rem 5px 1rem;
  align-items: center;
  font-size: 0.813rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: var(--black) !important;
  user-select: none;
  position: relative;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterTags-Close-Icon {
  width: 0.6875rem;
  height: 0.6875rem;
  margin-left: 0.5rem;
  font-size: 12px !important;
  color: #797876 !important;
  font-weight: 400 !important;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 8px;
}

.autocomplete-img {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  margin-right: 5px;
  border: 2px solid rgba(72, 94, 144, 0.15);
}

.autocomplete-label {
  align-self: center;
  font-size: 13px !important;
}

.autocomplete-icon-circle {
  padding: 5px;
  display: inline-block;
  border: 2px solid rgba(72, 94, 144, 0.15);
  border-radius: 50% !important;
  margin-right: 5px;
}

.autocomplete-icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
}

.commodity-mobile-img {
  width: 95px;
  max-width: 95px;
  height: 95px;
}

.trade-card-margin-bottom {
  margin-bottom: 6px;
}

.comments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comment {
  margin: 0 0 15px 0;
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6d6d6;
}

.comment-author {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  left: 0;
  position: absolute;
  top: 0;
}

.comment-author img {
  bottom: 2px;
  border-radius: 50%;
  display: block;
  width: 100%;
  position: absolute;
  top: 2px;
}

.comment-content {
  margin: 0 0 0 60px;
}

.comment-meta {
  font-size: 12px;
  color: #888da8 !important;
  font-weight: 500 !important;
}

.comment-content-h3 {
  font-size: 15px !important;
  font-weight: 700 !important;
  margin-bottom: 3px;
  color: #515365;
  font-family: var(--pboldfamily);
  line-height: 1;
}

.comment-padding-top {
  position: absolute;
  right: 0;
  top: 0;
}

.comment-text-desc {
  font-size: 14px !important;
  line-height: 27px !important;
  margin-bottom: 0 !important;
  margin-top: 2px;
}

.task-list {
  position: relative;
  margin-top: 18px;
}

.task-list li {
  margin-bottom: 20px;
  padding-left: 48px;
  position: relative;
}

.task-list:after {
  content: "";
  position: absolute;
  background: #ccc;
  height: 100%;
  width: 2px;
  top: 0;
  left: 18px;
  z-index: 1;
}

.task-list li .task-icon {
  position: absolute;
  left: 5px;
  top: -4px;
  border-radius: 50%;
  padding: 2px;
  color: var(--white);
  min-width: 1.8rem;
  min-height: 1.8rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 14px;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.header-autocomplete-label,
.header-dropdown {
  font-size: 14px !important;
}

.header-position {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 180px;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.header-position p {
  font-size: var(--bodysize) !important;
  line-height: 22px !important;
  margin: 25px auto;
  width: 440px;
}

.text-overflow-ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  margin-right: 10px;
}

.react-joyride__overlay {
  height: 100% !important;
}

.tour-h2 {
  font-size: 18px !important;
}

.commodityCard {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.homeCommodityCard {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.commodityCard-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin: 0px -13px;
}

/*trade card*/

.tradeCard-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin: 0px -13px;
}

.tradeCard {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.homeTradeCard {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.favouriteTradeCard {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.storeTradeCard {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.tradeCard-wrapper {
  position: relative;
  overflow: hidden;
  align-items: center;
  background-color: var(--white);
  padding: 10px;
}

.favouriteCommodityCard {
  flex: 0 0 16.5%;
  max-width: 16.5%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.userTradeCard-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin: 0px -16px;
}

.userTradeCard {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.userTradeCard-wrapper {
  position: relative;
  overflow: hidden;
  align-items: center;
  background-color: var(--white);
  padding: 9px;
}

.userTradeCardImage-wrapper {
  display: flex;
  align-items: flex-start;
}

.userTradeCardImage-wrapper img {
  width: 100px !important;
  height: 85px !important;
  object-fit: contain;
}

.userTrade-body {
  padding-left: 9px;
  z-index: 1;
}

/*apinotfound*/

.apiNotFoundloading h1 {
  margin: 230px auto 0 auto;
  color: var(--main-green-color);
  font-size: 30 !important;
  font-weight: 500 !important;
  text-align: center;
}

.apiNotFoundloading h2 {
  margin: 20px auto 30px auto;
  font-size: 25 !important;
  font-weight: 200;
  text-align: center;
  color: #676a6c;
}

.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: var(--main-green-color);
}

.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: "";
  border-radius: 50%;
  background: #eaeaea;
}

.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid var(--main-green-color);
  box-sizing: border-box;
  background: #eaeaea;
}

.gear.one {
  left: -130px;
}

.gear.two {
  top: -75px;
}

.gear.three {
  top: -235px;
  left: 130px;
}

.gear .bar {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: var(--main-green-color);
}

.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: "";
  border-radius: 2px;
  background: #eaeaea;
}

.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes anticlockwise {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes clockwiseError {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(30deg);
  }

  40% {
    transform: rotate(25deg);
  }

  60% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes anticlockwiseErrorStop {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-30deg);
  }

  60% {
    transform: rotate(-30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes anticlockwiseError {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-30deg);
  }

  40% {
    transform: rotate(-25deg);
  }

  60% {
    transform: rotate(-30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.gear.one {
  animation: anticlockwiseErrorStop 2s linear infinite;
}

.gear.two {
  animation: anticlockwiseError 2s linear infinite;
}

.gear.three {
  animation: clockwiseError 2s linear infinite;
}

.apiNotFoundloading .gear.one,
.apiNotFoundloading .gear.three {
  animation: clockwise 3s linear infinite;
}

.apiNotFoundloading .gear.two {
  animation: anticlockwise 3s linear infinite;
}

#noticePanelBody,
#newsPanelBody {
  height: 850px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

/*store css*/

.store-cover {
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  height: 300px !important;
}

.store-logo {
  bottom: 15px;
  display: inline-block;
  left: 15px;
  margin: 0;
  position: absolute;
  max-width: 140px;
  z-index: 1;
}

.store-logo>img {
  border-width: 3px;
  border-radius: 50%;
  max-width: 140px;
  border-color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 1);
  border-style: solid;
}

.website-btn {
  background: var(--white) none repeat scroll 0 0;
  border: 1px solid var(--default-color);
  border-radius: 10px;
  bottom: 20px;
  position: absolute;
  right: 20px;
  font-size: 13px;
  padding: 6px 15px;
  cursor: pointer;
  display: inline-block;
}

.follow-btn {
  background: var(--white) none repeat scroll 0 0;
  border: 1px solid var(--default-color);
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  padding: 4px 7px;
  display: inline-block;
}

.website-btn i,
.follow-btn i {
  margin-right: 5px;
}

.store-info {
  background: var(--white) none repeat scroll 0 0;
  display: block;
  width: 100%;
  padding: 12px;
  /* box-shadow: var(--box-shadow); */
  margin-bottom: 0.8rem;
}

.store-font-size {
  font-size: 13px !important;
  line-height: 16px;
  font-weight: 600;
  margin-top: 4px;
  color: rgba(102, 102, 102, 1);
  font-family: var(--pboldfamily);
}

.store-btn {
  font-size: 13px !important;
}

.store-info h3 {
  font-size: 18px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.storeMap {
  height: 280px;
}

.store-margin-bottom {
  margin-bottom: 12px;
}

.store-about {
  font-size: 14px !important;
  color: var(--default-color) !important;
  font-weight: 100 !important;
}

/* setting css */

.setting-ui {
  list-style-type: none;
  margin: 0;
  padding: 0.8rem 0.4rem;
}

.setting-body {
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 1rem;
  border: 0;
  color: #212b36;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1.5rem;
}

.setting-icon-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.8rem;
  border-radius: 3px;
  background-color: var(--background-color);
  color: transparent;
}

.setting-icon {
  color: var(--default-color);
}

.setting-title {
  font-weight: 700;
  font-family: var(--pboldfamily);
  margin: 0;
  color: var(--main-green-color);
}

.setting-subtitle {
  color: rgba(109, 113, 117, 1) !important;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

#languageCard .ibox-tools:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
}

#languageCard .ibox-tools.collapsed:after,
#companyFollowCard .ibox-tools.collapsed:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f106";
}

.setting-body:focus,
.setting-body:hover {
  background-color: var(--background-color);
  outline: none;
}

#language-title .setting-body:focus,
#language-title .setting-body:hover,
#language-title .setting-body,
#permissions-title .setting-body:focus,
#permissions-title .setting-body:hover,
#permissions-title .setting-body {
  background-color: var(--white);
  outline: none;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

#deleteAccount .setting-body {
  padding: 0.5rem 0 0.5rem 1rem !important;
}

.security-card {
  flex: 1 1 14rem;
  --webkit-box-flex: 1;
  --webkit-flex: 1 1 14rem;
}

.security-card-title {
  font-size: 18px !important;
  color: var(--black);
}

.security_content {
  --webkit-box-flex: 2;
  --webkit-flex: 2 1 20rem;
  --ms-flex: 2 1 20rem;
  flex: 2 1 20rem;
  max-width: 100%;
  min-width: 0;
  padding: 0;
}

.security-ui-card {
  background-color: var(--white);
  --webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  padding: 1rem;
  border-radius: 6px;
}

.security-section {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.speciallities-chk .custom-control-label {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.speciallities-chk .custom-control-label::before,
.speciallities-chk .custom-control-label::after {
  top: 0.1rem;
}

.require-color {
  color: #f5645f;
}

.input-with-icon {
  position: relative;
  width: 100%;
}

.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: var(--bodysize);
  color: #495057;
  font-style: normal;
  cursor: normal;
  /* pointer-events: none; */
}

.input-with-icon .filter-search-box,
.input-group .filter-search-box {
  margin: 0 !important;
  background: var(--white);
  border: 1px solid rgba(72, 94, 144, 0.15);
  color: #495057;
  border-radius: 6px;
  padding-left: 45px;
  height: 40px;
  font-size: 13px !important;
}

.input-group button {
  color: #495057 !important;
  border-radius: 0 !important;
}

.input-group .filter-search-box {
  height: 50px;
}

.filter_wraps {
  padding: 1rem 0;
  border-bottom: 1px solid #eef0f5;
}

.filter-ui {
  list-style: none;
  padding-left: 0;
}

.filter-ui li {
  padding: 0.5rem 0 0 0;
}

.filter-ui .radio-custom {
  opacity: 0;
  position: absolute;
}

.filter-ui .radio-custom:checked+label:before,
.filter-ui .radio-custom:not(:checked)+label:before {
  right: 0 !important;
  left: auto !important;
  position: absolute;
  top: 2px;
}

.filter-ui .radio-custom+.radio-custom-label:before {
  margin-right: 0;
}

.filter-ui .radio-custom:checked+label,
.filter-ui .radio-custom:not(:checked)+label {
  padding-left: 0 !important;
  width: 98% !important;
}

.filter-ui .radio-custom:checked+label,
.filter-ui .radio-custom:not(:checked)+label {
  position: relative !important;
  cursor: pointer !important;
  line-height: 20px !important;
  display: inline-block !important;
  font-size: 15px;
}

.filter-ui .radio-custom,
.filter-ui.radio-custom-label {
  margin: 0;
}

.radio-custom+.radio-custom-label:before {
  content: "";
  background: var(--white);
  border: 2px solid rgba(72, 94, 144, 0.15);
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  color: #495057;
}

.filter-ui .radio-custom:checked+label:after,
.filter-ui .radio-custom:not(:checked)+label:after {
  right: 0;
  left: auto;
}

.filter-ui .radio-custom:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-custom:checked+label:after,
.radio-custom:not(:checked)+label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  width: 15px;
  height: 15px;
  background: var(--main-green-color) !important;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: var(--white);
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-15 {
  padding: 15px;
}

.add-edit-cover {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  border-radius: 100%;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  left: 10px;
  position: absolute !important;
  text-align: center;
  top: 10px;
  width: 40px;
  z-index: 1;
}

.add-edit-cover i,
.add-edit-store-logo i {
  line-height: 38px;
}

.fileContainer {
  display: inline-block;
  font-size: 14px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.fileContainer [type="file"] {
  cursor: pointer;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.add-edit-store-logo {
  background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
  border: 1px solid #b3b3b3;
  border-radius: 100%;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 10px;
  text-align: center;
  width: 40px;
}

.add-edit-store-logo>label.fileContainer {
  color: var(--black);
  margin: 0;
  width: 100%;
}

.setting-location-div {
  flex: 0 0 auto;
  margin-right: 2rem;
  color: inherit;
  text-decoration: none;
}

.setting-location-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  border: 0.1rem solid rgba(201, 204, 207, 1);
  background: rgba(255, 255, 255, 1);
}

.setting-location-icon-div {
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.setting-location-icon-div i {
  font-size: 20px;
}

.setting-location-body {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.setting-location-title {
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
  align-items: stretch;
}

.setting-location-subtitle {
  margin-top: 1.6rem;
  margin-left: 0.5rem;
  max-width: 100%;
}

.setting-location-subtitle h3 {
  font-size: 15px !important;
}

.setting-location-badge {
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  background-color: rgb(40 167 69 / 15%) !important;
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
  color: var(--main-green-color);
  font-weight: 100 !important;
  line-height: 1.6rem;
  margin-top: -0.2rem !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: var(--bodysize) !important;
}

.setting-location-searchbox {
  margin: 0 !important;
  box-shadow: none !important;
  background: var(--white);
  border: 1px solid rgba(72, 94, 144, 0.15);
  color: #000000 !important;
  border-radius: 6px;
  padding: 0.375rem 0.75rem 0.375rem 45px !important;
  height: 40px;
  font-size: 14px !important;
}

.boder-bottom-solid {
  border-bottom: 1px solid #d6d6d6;
}

.form-control:focus {
  border-color: #ced4da;
}


#loginmodalid .modal-body,
#signupmodalid .modal-body,
#forgotpawordupmodalid .modal-body {
  padding: 25px 10px 25px 10px;
}

#loginmodalid .input-group,
#signupmodalid .input-group {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 6%);
}

#loginmodalid .form-control,
#signupmodalid .form-control {
  padding: 10px 17px !important;
  color: #495057 !important;
  width: 100% !important;
  min-height: 45px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

#signupmodalid .form-select {
  padding: 10px 17px !important;
  width: 100% !important;
  min-height: 45px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

#signupmodalid select option:checked,
option:hover {
  color: #b1adad !important;
}

#signupmodalid select option {
  color: var(--default-color) !important;
}

#loginmodalid small,
#signupmodalid small,
.forgotPasswordBtn {
  font-size: 15px !important;
}

.btn-green {
  background-color: var(--main-green-color);
  padding: 0.5rem 1rem !important;
  border-radius: 4px !important;
  color: var(--white) !important;
  font-size: 15px !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
  cursor: pointer;
  display: inline-block;
}

.btn-reset {
  background-color: var(--white) !important;
  border: 1px solid #b1b8bb !important;
  padding: 0.5rem 1rem !important;
  border-radius: 4px !important;
  color: #67757c !important;
  font-size: 15px !important;
  cursor: pointer;
  display: inline-block;
}

.btn-light {
  background-color: rgba(249, 250, 251, 1);
  padding: 0.5rem 1rem !important;
  border-radius: 4px !important;
  color: var(--main-green-color) !important;
  font-size: 15px !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
  cursor: pointer;
  display: inline-block;
}

.btn-light:hover,
.btn-light:focus {
  background-color: rgba(249, 250, 251, 1) !important;
  color: var(--main-green-color) !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
}

.btn-outline-green {
  background-color: var(--white);
  padding: 0.5rem 1rem !important;
  border: 1px solid var(--main-green-color) !important;
  color: var(--main-green-color) !important;
  font-size: 15px !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
  cursor: pointer;
  border-radius: 4px;
}

.btn-outline-green:hover,
.btn-outline-green:focus {
  background-color: var(--white) !important;
  color: var(--main-green-color) !important;
  border: 1px solid var(--main-green-color) !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
}

#companyContainer,
#varietyContainer,
#publicMarketContainer {
  display: block;
  position: relative;
}

#companyContainer .ui-autocomplete,
#varietyContainer .ui-autocomplete,
#publicMarketContainer .ui-autocomplete {
  position: absolute;
  max-height: 180px;
}

.padding-right {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.setting-title-h1 {
  color: rgba(32, 34, 35, 1);
  font-size: var(--bodysize);
  font-weight: 100 !important;
}

.confirm-email-title {
  font-size: 20px !important;
  color: rgba(32, 34, 35, 1);
}

/* email verifu ui */

#emailVerifyAlert {
  background-color: #f5f5f5;
  border-color: 1px solid #d3d3d3;
}

#emailVerifyAlert .ui-banner {
  padding-right: 1.6rem;
}

.ui-banner {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 32px;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
  padding-right: 1.6rem;
}

.ui-banner_heading {
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--black) !important;
}

.ui-banner_heading span {
  font-size: var(--bodysize) !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.ui-banner_content {
  padding: 0.4rem 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

.ui-banner_content-button {
  padding-top: 0.5rem !important;
}

.ui-banner_content-button .btn-outline-secondary {
  color: var(--black) !important;
  border-color: #d3d3d3 !important;
  font-weight: 100 !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  background-color: transparent;
}

.ui-banner_content-button .btn {
  font-size: 1rem !important;
}

.notfound-page {
  background-image: url(../assets/img/404Page.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.notfound-page-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notfound-page-title {
  display: block;
  color: #686868;
  font-size: 100px !important;
  font-weight: 800;
}

.notfound-page-subtitle {
  margin-bottom: 20px !important;
  font-weight: 600;
  font-size: 28px !important;
  color: var(--black);
}

.notfound-page-text {
  display: block;
  color: #686868;
  text-align: center;
}

#storeDetails,
#userTradeDetails,
#userStore {
  padding-top: 45px;
}

/* email verifu ui */

#emailVerifyAlert {
  background-color: #f5f5f5;
  border-color: 1px solid #d3d3d3;
}

#emailVerifyAlert .ui-banner {
  padding-right: 1.6rem;
}

.ui-banner {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 32px;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
  padding-right: 1.6rem;
}

.ui-banner_heading {
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--black) !important;
}

.ui-banner_heading span {
  font-size: var(--bodysize) !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.ui-banner_content {
  padding: 0.4rem 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

.ui-banner_content-button {
  padding-top: 0.5rem !important;
}

.ui-banner_content-button .btn-outline-secondary {
  color: var(--black) !important;
  border-color: #d3d3d3 !important;
  font-weight: 100 !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  background-color: transparent;
}

.ui-banner_content-button .btn {
  font-size: 1rem !important;
}

.margin-right-5 {
  margin-right: 5px;
}

.search-box .form-group .form-control {
  background-color: var(--white);
  border: 1px solid rgb(235, 235, 235) !important;
  border-radius: 10px;
  font-size: 14px !important;
  color: #888 !important;
  height: 50px;
  padding: 0.375rem 0.75rem 0.375rem !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.trade-details-page h2 {
  font-size: 22px !important;
  font-weight: 500;
  margin: 0 0 5px !important;
  color: var(--black);
}

.trade-details-page p {
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-bottom: 0;
  color: #696969;
  margin-bottom: 0;
}

.margin-bottom-2 {
  margin-bottom: 4px !important;
}

.heading-2 {
  margin: 0 0 10px;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: var(--black) !important;
}

.details-bottom-border {
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #ced0d4;
}

.bazaar-title-bgimage {
  background: linear-gradient(120deg, #21170e 0%, transparent 100%),
    url("../assets/img/BazaarTitle.jpg");
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.news-notice-title-bgimage {
  background: linear-gradient(120deg, var(--black) 0%, transparent 100%),
    url("../assets/img/NewsNoticeTitle.jpg");
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.commodity-title-bgimage {
  background: linear-gradient(120deg, #21170e 0%, transparent 100%),
    url("../assets/img/CommodityTitle.jpg");
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.store-title-bgimage {
  background: linear-gradient(120deg, #21170e 0%, transparent 100%),
    url("../assets/img/StoreTile.jpg");
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.filter-main-title {
  font-size: 22px !important;
  color: var(--white) !important;
  line-height: normal !important;
  margin-bottom: 2px !important;
  padding-top: 5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  text-transform: capitalize;
  font-family: Jost, sans-serif;
  font-style: italic;
}

.div-content-area {
  position: relative;
  z-index: 999;
  padding: 10px 30px 10px 30px;
}

.bazaar-title-bgimage img,
.commodity-title-bgimage img,
.store-title-bgimage img {
  height: 40px;
  width: auto;
  margin-bottom: 10px;
}

.userTrade-search-box {
  background: var(--white);
  border: 0 !important;
  color: var(--black);
  border-radius: 6px;
  height: 50px;
  font-size: 14px !important;
  box-shadow: var(--box-shadow);
}

.dropdown-item:hover,
.dropdown-item.active {
  color: var(--black) !important;
  text-decoration: none !important;
}

.text-color-start {
  color: #ea6b66;
}

.start-text {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.card-title-h2 {
  font-size: var(--bodysize);
}

.start-icon {
  color: #fab570;
}

.border-green {
  border: 1px solid var(--main-green-color);
  border-radius: 6px;
  overflow-wrap: break-word;
}

.circle-icon {
  height: 40px;
  width: 40px;
  margin-left: 5px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-img-absolute {
  height: 100%;
  width: 100%;
}

.filter-scrollbar {
  overflow: hidden !important;
  max-height: calc(100vh - 255px) !important;
}

.filter-scrollbar:hover {
  overflow: auto !important;
  scroll-behavior: contain !important;
}

.home-banner {
  background: linear-gradient(120deg, #21170e 0%, transparent 100%),
    url("../assets/img/homeBackground/Home.jpg");
  background-size: cover !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  padding: 45px 15px 20px 15px !important;
  position: relative;
}

.filters-toolbar {
  display: flex;
  width: 100%;
}

.select-wrapper {
  display: inline-block;
  position: relative;
}

#SortBy {
  font-size: 14px;
  font-weight: 400;
  margin-right: 0;
  position: relative;
  border: 1px solid rgba(72, 94, 144, 0.15) !important;
  z-index: 1;
  background-color: var(--white);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem !important;
}

#orderby select option:checked,
option:hover {
  color: #b1adad !important;
}

#orderby select option {
  color: var(--default-color) !important;
}

.store-sticky {
  position: fixed;
  top: 51;
  z-index: 1000 !important;
  width: 100%;
}

#mobile-nav {
  background-color: var(--background-color);
}

.goog-te-gadget .goog-te-combo {
  margin: 0px 0 !important;
}

.goog-te-combo {
  border-radius: 0 !important;
  border: 1px solid var(--background-color) !important;
  background-color: var(--background-color) !important;
  padding: 6.2px;
  font-size: 14px !important;
}

.goog-logo-link {
  display: none;
}

.goog-te-gadget {
  color: transparent !important;
}

.footer-default-text {
  border: 1px solid var(--background-color);
  font-family: arial;
  align-self: center;
  font-size: 14px !important;
  color: #666 !important;
  background-color: var(--background-color) !important;
  padding: 5px !important;
  margin-bottom: 16px;
}

.tradedetails-tradeCard,
.tradedetails-storeCard {
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.goog-te-combo:focus {
  border-radius: 0 !important;
  outline: 1px solid var(--background-color) !important;
}

.download-app a {
  display: block;
  margin-right: 8px;
  margin: 8px 0;
}

.download-app a img {
  background-size: 100% 100%;
  width: 120px;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
}

#tradeDetails .trade-header-title,
.commodity-header-title {
  font-family: var(--pboldfamily);
  font-size: 19px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  color: var(--black) !important;
}

.commodity-details-price {
  font-size: var(--bodysize);
  font-weight: 700;
  line-height: 0.9;
  margin-bottom: 0;
  color: var(--black);
  font-family: var(--pboldfamily);
}

.flxColLft {
  flex: 1 1 auto;
  max-width: 100%;
  min-width: 25%;
  padding: 0;
}

.font-size-13 {
  font-size: 13px !important;
}

.trade-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.button-negative,
.button-positive {
  font-size: 13px !important;
  width: 26px;
  height: 26px;
  color: #070707;
  background-color: #f0f0f0;
  border-radius: 50% !important;
  position: relative;
  text-align: center;
  vertical-align: middle;
  user-select: none;
}

.button-negative i,
.button-positive i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.qtyInput {
  width: 60px;
  text-align: center;
  border: none;
  outline: none;
  font-size: var(--bodysize) !important;
  display: inline-block;
}

.carttDetlsIputCntl {
  border: 1px solid rgb(218, 225, 231);
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  outline: none !important;
  min-height: 26px;
}

.qty-div .btn-outline {
  background: var(--white) !important;
  color: var(--main-green-color) !important;
  border: 1px solid var(--main-green-color) !important;
  margin-right: 10px;
}

.qty-div .btn,
#contactStore.btn,
.qty-div .btn:hover,
#contactStore.btn:hover {
  background: var(--main-green-color);
  color: var(--white);
  padding: 0.375rem 1.75rem;
  font-size: 14px !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
}

.disabledbutton {
  pointer-events: none !important;
  opacity: 0.65 !important;
}

.trade-details-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.trade-details-list ul li {
  padding-left: 22px;
  position: relative;
  font-size: 13px;
  color: #8d8d8d;
}

.trade-details-list ul li+li {
  margin-top: 5px;
}

.trade-details-list ul li::before {
  content: "\f35a";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: var(--main-green-color);
}

.trade-details-list ul li>span {
  color: #696868;
}

#tradeDetails .default-trade-img {
  height: 348px !important;
  /* border-radius: 6px !important; */
  /* object-fit: contain !important; */
}

.greyBox {
  color: #696868 !important;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px !important;
}

.greyBoxRow {
  display: flex;
  width: 50%;
}

.greyBoxLblCol {
  font-weight: 600;
  min-width: 50%;
  display: inline-block;
  color: #666;
  font-family: var(--pboldfamily);
  line-height: 2;
}

.greyBoxRgtCol a:hover {
  color: var(--main-green-color) !important;
}

.greyBoxRgtCol,
.greyBoxRgtCol a {
  display: block;
  font-weight: 500;
  color: #222 !important;
  font-size: 14px;
  width: 50%;
  line-height: 2;
}

.greyBoxRgtCol i {
  font-weight: 500;
  color: #222 !important;
  font-size: 14px;
}

/*cart delete modal*/

#deleteCartItemModal {
  max-width: 312px;
  width: 100%;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: fit-content;
}

#deleteCartItemModal .deleteCartItemModal-title {
  color: var(--black);
  font-size: 15px;
}

#deleteCartItemModal .modal-header {
  margin: 1rem 1rem 0 1rem;
}

#deleteCartItemModal .modal-header .close {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 20px !important;
}

#deleteCartItemModal .modal-body {
  padding: 0 !important;
  color: rgb(105, 107, 121) !important;
  font-size: 14px !important;
  font-weight: 200 !important;
  margin: 1rem 1rem 1rem 1rem !important;
}

#deleteCartItemModal .modal-footer {
  padding: 0 !important;
  display: unset !important;
  align-items: center;
  text-align-last: center;
  border-top: 0;
  margin: 0.3rem 1rem 0.3rem 1rem !important;
  font-size: var(--bodysize) !important;
}

#deleteCartItemModal.modal-dialog {
  margin: auto !important;
}

.modalDivider-root {
  width: 312px;
  height: 1px;
  border: none;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

/* addresses */

.address-plus-icon {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  margin-bottom: 12px;
  color: #e0e0e0;
  cursor: pointer;
}

.address-plus-icon:hover {
  color: var(--main-green-color);
}

.address-plus-icon:after,
.address-plus-icon:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 4px;
  background: currentColor;
  border-radius: 1.5px;
  top: calc(50% - 2px);
}

.address-plus-icon:before {
  left: calc(50% - 16px);
}

.address-plus-icon:after {
  transform: rotate(90deg);
  left: calc(50% - 16px);
}

.add-address-text {
  color: var(--text) !important;
  font-weight: 400 !important;
  font-size: var(--bodysize) !important;
  margin-bottom: 0;
}

.default-badge {
  background-color: rgb(40 167 69 / 15%) !important;
  border: 1px solid var(--main-green-color) !important;
  color: var(--main-green-color) !important;
  padding: 0.2rem 1.2rem !important;
  font-size: 12px;
  font-weight: 400;
  line-height: unset;
}

.delivery-badge {
  background-color: transparent !important;
  border: 1px solid #507c3a !important;
  color: #507c3a !important;
  padding: 0.2rem 1.2rem !important;
  font-size: 12px;
  font-weight: 400;
  line-height: unset;
}

.billing-badge {
  background-color: transparent !important;
  border: 1px solid #8b5d39 !important;
  color: #8b5d39 !important;
  padding: 0.2rem 1.2rem !important;
  font-size: 12px;
  font-weight: 400;
  line-height: unset;
}

/*Filter*/

h3.widget-title {
  color: #202020;
  font-size: 15px !important;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
}

.filter-div {
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}

.filter-div ul {
  list-style: none;
  padding: 0;
  padding-top: 10px;
}

.filter-div ul li:first-child {
  margin: 0 0 5px;
}

.filter-div .radio-custom {
  opacity: 0;
  position: absolute;
}

.filter-div .radio-custom:checked+label:before,
.filter-div .radio-custom:not(:checked)+label:before {
  right: 0 !important;
  left: auto !important;
  position: absolute;
  top: 2px;
}

.filter-div .radio-custom:checked+label,
.filter-div .radio-custom:not(:checked)+label {
  padding-left: 0 !important;
  width: 98% !important;
  position: relative !important;
  cursor: pointer !important;
  line-height: 20px !important;
  display: inline-block !important;
  font-size: 15px;
  color: #888 !important;
}

.radio-custom+.radio-custom-label:before {
  content: "";
  background: var(--white);
  border: 2px solid rgba(72, 94, 144, 0.15);
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  margin-right: 0;
}

.filter-div .radio-custom:checked+label:after,
.filter-div .radio-custom:not(:checked)+label:after {
  right: 0;
  left: auto;
}

.filter-div .radio-custom:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-custom:checked+label:after,
.radio-custom:not(:checked)+label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  width: 15px;
  height: 15px;
  background: var(--main-green-color) !important;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: var(--white);
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-div .form-item {
  position: relative;
}

.filter-div .form-item input:focus,
.filter-div .form-item select:focus {
  border-color: rgba(72, 94, 144, 0.15);
  box-shadow: none;
}

.filter-div .form-item label {
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  margin: 12px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #888;
}

.filter-div .form-select {
  font-size: 14px;
}

.filter-div .form-item .active {
  color: #767676;
  top: -8px;
  font-size: 14px;
  left: 17px;
  padding: 0;
  margin: 0;
}

.filter-div :focus {
  outline: none;
}

.filter-div .form-control {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid rgba(72, 94, 144, 0.15);
  color: #888;
  height: 45px;
}

.filter-div select option:checked,
option:hover {
  color: #b1adad;
}

.filter-div select option {
  color: var(--default-color);
}

.filter-div select:disabled {
  color: #b1adad;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
}

.btn-outline-success:hover:disabled {
  background-color: var(--white) !important;
  color: var(--main-green-color) !important;
}

.banner-image {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
}

.unavailable {
  opacity: 0.5;
  pointer-events: none;
}

/*grid css*/

.grid-templates-columns {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  display: grid;
  gap: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
}

.prideDetail-list.grid-templates-columns {
  gap: 3rem !important;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-end-8 {
  grid-column-end: 8;
}

.storeInformation .store-media {
  display: flex;
  -webkit-box-align: start;
  align-items: center;
}

.storeInformation .store-media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  align-self: center;
}

.btn.btn-icon i {
  margin-right: 4px;
}

.inStock {
  color: #3bb77e !important;
}

.outStock {
  color: #f74b81 !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.filters-toolbar {
  margin-left: 5px;
}

.header-form {
  height: 49px;
  width: 35%;
  margin: 0px 50px;
  border-radius: 8px;
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
}

.header-form:focus-within {
  background: var(--white);
  border: 2px solid var(--main-green-color) !important;
  border-radius: 8px;
}

.header-form input {
  border: none;
  outline: none !important;
  background: none;
  width: 100%;
  height: 45px;
  font-size: 15px;
  padding-left: 15px;
}

.header-form input:focus {
  outline: none !important;
}

.header-form button i {
  width: 45px;
  height: 45px;
  font-size: 15px;
  line-height: 45px;
  text-align: center;
  border-radius: 8px;
  color: #555555;
  display: inline-block;
  transition: all linear 0.3s;
}

.storeCard-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  /* margin: 0px -13px; */
}

.storeCard {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.storeListCard-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin: 0px -13px;
}

.storeListCard {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px;
}

.cardBorder {
  border-radius: 6px;
}

.cardHeader {
  background: none;
  padding: 1rem 1.2rem 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.cardTitle {
  padding: 0 0 8px 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.footer-list-wrap {
  padding-top: 30px;
}

.footer-checkout-list-wrap {
  padding: 25px 0;
  text-align: center;
}

.footer-list-wrap ul.footer-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1;
  flex-flow: row nowrap;
}

.footer-list-wrap ul.footer-list li.ftlink-li:last-child {
  width: unset;
  align-self: center;
}

.footer-info .logo p {
  margin-top: 13px;
  margin-bottom: 0 !important;
  display: flex;
  font-size: 13px;
}

.footer-list-wrap li h2.ft-title {
  font-size: var(--bodysize) !important;
  font-weight: 700 !important;
  font-family: var(--pboldfamily);
  text-align: left;
  line-height: 19px !important;
  margin-bottom: 0;
}

.footer-sublist {
  margin: 8px 0 0;
  font-size: 14px;
  list-style: none;
  padding: 0;
  display: block;
  font-weight: 600;
  text-align: left;
  line-height: 19px;
  font-family: var(--pboldfamily);
  color: #5e5e5e !important;
}

.footer-sublist li.ftsublink-li {
  margin-bottom: 4px;
  line-height: 25px;
}

.page-header {
  padding-bottom: 15px;
}

.page-header h3 {
  font-size: 22px !important;
  margin-bottom: 0 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  color: var(--black);
  font-family: var(--pboldfamily);
}

.page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 0;
}

.page-header .breadcrumb li {
  color: #717171 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.page-header .breadcrumb .breadcrumb-item.active {
  color: var(--main-green-color) !important;
}

.page-header .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  content: "/";
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
}

.fw-semibold {
  font-weight: 600 !important;
  font-family: var(--pboldfamily);
}

.fw-normal {
  font-weight: 400 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.line-height {
  line-height: unset !important;
}

.chart {
  padding-top: 10px;
  width: 100%;
}

.chart .right {
  stroke: var(--white);
  fill: var(--main-green-color);
}

.chart .left {
  stroke: var(--white);
  fill: #6e9d753b;
}

.chart text {
  fill: black;
  font-size: 14px;
}

.chart text.name {
  fill: var(--text);
  font-size: 14px;
}

.chart text.title {
  fill: black;
  font-size: var(--bodysize);
}

.custom-shadow {
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.tradeDetails.row>*,
#tradeCardDiv .row>* {
  padding-right: calc(0.8rem * 0.5);
  padding-left: calc(0.8rem * 0.5);
}

.speciallities-badge {
  font-size: 11px !important;
  letter-spacing: 0.8px;
  border-radius: 3px;
  padding: 5px 14px !important;
  margin-bottom: 0.5rem !important;
  white-space: unset !important;
}

.store-card-badge {
  display: inline-block;
  padding: 0.35em 0.5em !important;
  font-size: 0.75em !important;
  font-weight: 600;
  line-height: 1 !important;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  font-family: var(--pboldfamily);
  margin: 2;
}

.btn-invoice {
  padding: 0.25rem 0.5rem !important;
  font-size: 13px !important;
  border-radius: 0.2rem !important;
}

.btn-invoice {
  color: var(--white) !important;
}

.btn-invoice:hover {
  color: var(--white) !important;
}

.section-title {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 2px;
  align-items: center;
}

.section-title h3 {
  font-weight: 400 !important;
  font-size: 17px !important;
  color: #333 !important;
  margin-bottom: 0;
}

.section-title a {
  color: var(--default-color) !important;
}

.commodity-analytics-title {
  font-size: 18px !important;
  color: var(--black) !important;
  font-family: var(--pboldfamily);
}

.gap-3 {
  gap: 1rem !important;
}

#commodityPriceAnalyticsMap {
  height: 562px;
}

.inquiry-modal-title {
  font-size: 1.015625rem !important;
  color: #495057;
  font-family: var(--pboldfamily);
}

#tradeinquiryModal .btn {
  font-size: 14px !important;
  padding: 0.375rem 1.75rem !important;
  font-weight: 600 !important;
  font-family: var(--pboldfamily);
}

.mb-8 {
  margin-bottom: 2rem;
}

.font-semibold {
  font-weight: 600;
  font-family: var(--pboldfamily);
}

#contactus-div .btn-social-icon {
  box-shadow: none;
  width: 30px !important;
  height: 30px !important;
  margin: 5 !important;
}

#contactus-div .btn-social-icon i {
  font-size: 18px !important;
  line-height: 31px !important;
}

.news-part {
  background-size: cover !important;
  padding: 70px 0px;
  position: relative;
  z-index: 1;
  background: url("../assets/img/BazaarTitle.jpg");
  background-position: center;
}

.news-part::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  opacity: 0.8;
  z-index: -1;
}

.news-form {
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  background: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.news-form input {
  width: 100% !important;
  height: 40px !important;
  padding: 0px 20px !important;
  line-height: 50px !important;
}

.news-form button {
  width: 180px;
  height: 40px !important;
  font-size: 14px !important;
  font-weight: 500;
  padding: 10px 0px !important;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
}

.news-text h2 {
  font-size: 24px !important;
  color: var(--white) !important;
  margin-bottom: 14px;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.cart-body {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem;
}

.empty-address-icon {
  width: 130px;
}

.noAddress-card {
  padding: 30px 10px 10px 10px;
  text-align: center;
}

.noAddress-card .noAddress-infoText {
  margin: 30px 0px 10px 0px;
}

.noAddress-card .noAddress-infoText .noAddress-heading {
  font-weight: 800;
  margin-bottom: 5px;
  font-family: var(--pboldfamily);
  font-size: var(--bodysize);
}

.noAddress-card .noAddress-infoText .noAddress-subHeading {
  color: #7e818c;
  font-size: 14px;
}

.noAddress-card .noAddress-button {
  margin: 20px auto;
  border: 0.5px solid var(--main-green-color);
  border-radius: 4px;
  font-weight: 800;
  color: var(--main-green-color);
  padding: 12px;
  text-align: center;
  width: 180px;
  cursor: pointer;
  font-family: var(--pboldfamily);
  font-size: 15px;
}

.gap-2 {
  gap: 0.5rem !important;
}

.pro-group {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #e6edef;
}

.two-line-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal !important;
  height: 3em;
}

.home-padding {
  padding: 1rem 1rem 0.5rem 1rem;
}

.font-size-12 {
  font-size: 12px;
}

/* mobile app*/
.mobile-app-wrap {
  position: relative;
  padding: 40px 0 100px;
  color: var(--white);
  overflow: hidden;
  z-index: 99;
}

.mobile-app-wrap:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
}

.mobile-app-wrap .overlay {
  top: 0;
  width: 100%;
  opacity: 1;
  background: var(--white);
}

.mobile-app-wrap:after {
  display: block;
  background-size: cover;
}

.mobile-app-wrap .overlay,
.mobile-app-wrap:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
}

.mobile-app-wrap h1 {
  font-size: 42px !important;
  margin-bottom: 1rem !important;
  font-weight: 400 !important;
  color: #000 !important;
}

.mobile-app-wrap img {
  margin-left: auto;
  width: 100%;
  height: 100%;
}

.text-20 {
  color: var(--default-color);
  font-size: 1.01625rem;
  font-weight: 300;
  letter-spacing: 0.2px;
  margin-bottom: 1rem !important;
}

.half-button {
  border-radius: 25px !important;
  color: var(--white) !important;
  background-color: var(--main-green-color);
  border-color: var(--main-green-color);
  font-size: 20px !important;
  box-shadow: var(--box-shadow);
}

.half-button i {
  font-size: 20px !important;
  padding-right: 5px;
}

.half-button:hover {
  color: var(--white) !important;
  background-color: var(--main-green-color) !important;
}

.cat-menu-img {
  display: inline-block;
  border: 2px solid rgb(243 246 250);
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(243 246 250/var(--tw-bg-opacity));
  width: 55px;
  height: 55px;
}

.cat-menu-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cart-menu-title {
  color: var(--white) !important;
  font-size: 17px !important;
}

.filter-btn {
  font-size: 14px !important;
  font-weight: 500;
  height: 40px;
  width: 90px;
  padding: 10px 0;
  text-align: center;
  color: #2b2f4c;
  background: var(--white);
  border-radius: 5px;
  border: 1px solid #efefef;
}

.department-search-div {
  background-color: #5d4257;
  background-image: linear-gradient(315deg, #A5C7B7 0%, #5d4257 74%);
  padding: 20px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.filter-modal {
  top: 66;
  right: 0 !important;
  width: 385px;
  bottom: 0 !important;
  position: fixed;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: var(--white);
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
  display: none;
}

.filter-modal-header {
  padding: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(315deg, #A5C7B7 0%, #5d4257 74%);
  color: var(--white);
  position: sticky;
  top: 0;
}

.filter-modal-header-title {
  font-size: 18px;
  font-weight: 500;
}

.filter-modal-header-close-btn {
  padding: 5px;
  font-size: 20px;
  background: #f7f7f7;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 0;
}

.filter-modal-body {
  flex-grow: 1;
  overflow-y: auto;
}

.filter-modal-body-items {
  padding: 1rem 1rem 0;
}

.filter-modal-body-items-title {
  font-size: var(--bodysize);
  font-weight: 500;
  color: #2b2f4c;
  margin-bottom: 0;
}

.filter-modal-item-body {
  height: 200px;
  width: 100%;
  margin-top: 15px;
  overflow-y: auto;
}

.filter-modal-item-body-unset-height {
  width: 100%;
  margin-top: 15px;
}

.filter-modal-select {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.filter-modal-select li {
  margin-right: 0;
  width: 100%;
  height: 40px;
  position: relative;
  text-align: left;
  display: block;
  margin-bottom: 0px;
  border-bottom: 0;
}

.filter-modal-select input[type="radio"] {
  display: none;
}

.filter-modal-select label,
.filter-modal-select input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.filter-modal-select label {
  padding: 7px 20px;
  cursor: pointer;
  background: #f9f9f9;
  color: #2b2f4c;
  border-radius: 5px;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-bottom: 5px;
}

.filter-modal-select input[type="radio"]:checked+label,
.Checked+label,
.filter-modal-select input[type="radio"]:hover+label {
  background: var(--main-green-color);
  color: var(--white);
}

/* .filter-modal-item-body::-webkit-scrollbar-track,
#companyContainer .ui-autocomplete::-webkit-scrollbar-track,
#searchVarietyTextBox .ui-autocomplete::-webkit-scrollbar-track,
.filter-modal-body select::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(9, 7, 7, 0.3) !important;
  background-color: #F5F5F5 !important;
}

.filter-modal-item-body::-webkit-scrollbar,
#companyContainer .ui-autocomplete::-webkit-scrollbar,
#searchVarietyTextBox .ui-autocomplete::-webkit-scrollbar,
.filter-modal-body select::-webkit-scrollbar {
  width: 5px !important;
  background-color: #F5F5F5 !important;
}

.filter-modal-item-body::-webkit-scrollbar-thumb,
#companyContainer .ui-autocomplete::-webkit-scrollbar-thumb,
#searchVarietyTextBox .ui-autocomplete::-webkit-scrollbar-thumb,
.filter-modal-body select::-webkit-scrollbar-thumb {
  background-color: #c1c1c1 !important;
  border: 1px solid #c1c1c1 !important;
} */

.filter-modal-body input {
  padding-left: 2.5em !important;
  padding-right: 1em !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-color: rgba(72, 94, 144, 0.1);
  border-radius: 0.3rem !important;
  width: 100%;
  height: 40px;
  color: #2b2f4c !important;
}

.filter-modal-body input:focus {
  outline: 0;
  border-color: rgba(72, 94, 144, 0.1) !important;
}

.filter-modal-search-icon {
  font-size: var(--bodysize) !important;
  position: absolute;
  top: 12px !important;
  left: 10px !important;
}

.filter-modal-body .form-select {
  margin-bottom: 5px;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 0.3rem !important;
  width: 100%;
  height: 40px;
  color: #2b2f4c;
}

#companyContainer .ui-autocomplete {
  position: absolute;
  max-height: 95px;
}

.selectdepartmentmodal .modal-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 0;
}

.selectdepartmentmodal-header {
  background: linear-gradient(315deg, #A5C7B7 0%, #5d4257 74%);
  color: var(--white);
  padding: 15px 20px;
  border-bottom: 0;
}

.selectdepartmentmodal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.selectdepartmentmodal li {
  width: 33.333%;
  vertical-align: middle;
  display: inline-block;
  list-style: none;
}

.selectdepartmentmodal .single-cat-item {
  text-align: center;
  padding: 20px 10px;
  display: block;
}

.selectdepartmentmodal .single-cat-item .icon {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.selectdepartmentmodal .single-cat-item .icon img {
  width: 60px;
}

.selectdepartmentmodal .single-cat-item .text {
  font-size: 15px;
  font-weight: 500;
  color: #2b2f4c;
}

#departmentModal .modal-content {
  background: var(--white) !important;
  border: 0 !important;
}

.selectdepartmentmodal .single-cat-item:hover {
  background-color: #e6edef;
}

.moredepartment-btn {
  display: block;
  text-align: center;
  border-top: 1px solid #efefef;
  padding: 20px;
  font-size: var(--bodysize);
  font-weight: 500;
  color: #2b2f4c;
}

.moredepartment-btn i {
  margin-right: 5px;
}

.product-list-view {
  margin-top: 6px;
}

.product-sort {
  float: right;
  margin-right: 10px;
  margin-top: 1px;
}

.commodity-card-content {
  overflow: hidden;
  position: relative;
  padding: 15px 18px 0 18px;
}

.commodity-card-content-image {
  height: 110px;
  text-align: center;
}

.commodity-card-content-image img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  position: relative;
}

.commodity-card-content-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
  line-height: 1;
}

.commodity-card-content-label-text {
  font-size: 12px !important;
  padding: 5px 7px !important;
  border-radius: 0.25rem;
  color: var(--white);
  text-align: center;
}

.commodity-card-content-wrap {
  padding: 2px 10px 10px 10px;
}

.commodity-card-content-title {
  color: #adadad;
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 600;
  font-family: var(--pboldfamily);
}

.commodity-card-content-wrap h2 {
  font-size: var(--bodysize) !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--main-green-color);
}

.commodity-card-content-wrap i,
.commodity-card-content-wrap-price i {
  margin-right: 5px;
}

.commodity-card-content-wrap-price {
  font-size: var(--bodysize);
  color: var(--main-green-color);
}

.post-ibox-content {
  padding: 10px !important;
  margin-bottom: 0 !important;
}

.post-image {
  width: 100px;
  height: 90px;
  margin-right: 12px;
  float: left;
  object-fit: cover;
  border-radius: 10px;
}

.post-content h2 {
  color: var(--default-color);
  font-weight: 500 !important;
  line-height: 20px !important;
  font-size: 14px !important;
  height: 3em;
  overflow: hidden;
  margin-bottom: 15px;
  font-family: var(--pboldfamily)
}

.post-tags span {
  margin-right: 5px;
  color: #999999;
  font-size: 12px;
}

.product-image img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  object-fit: contain;
}

.product-content {
  width: 100%;
  margin-left: 10px;
  padding-left: 10px;
}

.margin-bottom-3 {
  margin-bottom: 3px;
}

.margin-bottom-1 {
  margin-bottom: 1px;
}

.product-trade-title {
  font-weight: 700 !important;
  font-size: var(--bodysize) !important;
  color: var(--main-green-color);
  margin-bottom: 3px !important;
  font-family: var(--pboldfamily);
}

.product-trade-price {
  font-size: var(--bodysize) !important;
  color: var(--main-green-color);
  font-weight: 700 !important;
  font-family: var(--pboldfamily);
}

.post-header {
  margin-bottom: 15px;
}

.post-header .title,
#newsmodalid .modal-title,
#noticesmodalid .modal-title {
  margin-bottom: 3px !important;
  font-size: 19px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  color: var(--black);
  font-family: var(--pboldfamily);
}

.post-header .title {
  padding-right: 20px;
}

.post-meta {
  margin-bottom: 15px;
}

.post-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}

.post-meta ul li {
  margin-right: 20px;
}

.rt-meta {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #878c9f;
  font-family: var(--pboldfamily);
}

.rt-meta .icon {
  margin-right: 0.25rem;
  font-size: 0.875rem !important;
}

.share-box-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.social-share-style {
  margin-bottom: 0 !important;
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}

.social-share-style li {
  margin-right: 6px;
}

.post-body {
  margin-bottom: 15px;
}

.post-body p {
  margin-bottom: 12px !important;
  font-size: 16px !important;
  color: #726d73;
  line-height: 25px;
  letter-spacing: .41px;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.post-row .row>* {
  padding-left: calc(1.5rem/2);
  padding-right: calc(1.5rem/2);
}

.public-page-body {
  padding: 20px 0;
}

.public-content h3 {
  font-size: 21px !important;
  font-weight: 600 !important;
  color: var(--black) !important;
  margin-bottom: 0.7em;
  font-family: var(--pboldfamily);
}

.public-content h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #3e4555 !important;
  margin-bottom: 0.7em;
  font-family: var(--pboldfamily);
}

.public-content h5 {
  font-size: var(--bodysize) !important;
  font-weight: 600 !important;
  color: #3e4555 !important;
  margin-bottom: 0.7em;
  font-family: var(--pboldfamily);
}

.public-content p,
.public-content address {
  font-size: 15px !important;
  color: #6c757d !important;
  line-height: 1.7em !important;
  margin-bottom: 1rem !important;
  word-break: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.store-custom-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.store-custom-card .store-custom-card-img-wrap {
  position: relative;
  padding: 8px 8px 5px;
}

.store-custom-card .store-custom-card-img-wrap img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid #ececec;
  padding: 5px;
}

.store-custom-card .store-custom-card-content-wrap {
  padding: 15px 15px 0px 5px;
}

.store-custom-card h6 {
  margin-bottom: 5px !important;
  color: #999 !important;
  font-size: 14px !important;
}

.store-custom-card-badge {
  padding: 0 7px 8px;
}

.store-card-title {
  font-weight: 600 !important;
  color: #242934 !important;
  font-size: var(--bodysize) !important;
  font-family: var(--pboldfamily);
  margin-bottom: 3px !important;
  line-height: 1.2 !important;
}

.store-online-img {
  height: 75px;
  width: 75px;
  min-width: 75px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.25rem;
}

.store-online-desc h6 {
  color: #000;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  font-size: 16px !important;
  font-family: var(--pboldfamily);
  margin-bottom: 2px !important;
}

.store-online-desc small {
  font-size: 13px !important;
  color: var(--default-color);
  line-height: 1.2 !important;
}

.Toastify__toast {
  min-height: 64px !important;
  padding: 15px !important;
}

.Toastify__toast-body {
  margin: 0 !important;
}

.Toastify__toast--success {
  font-size: 14px !important;
  border-radius: 15px !important;
  background: var(--main-green-color) !important;
  color: var(--white) !important
}

.Toastify__toast--error {
  font-size: 14px !important;
  border-radius: 15px !important;
  background: #c52965 !important;
  color: var(--white) !important
}

.Toastify__toast--success .Toastify__close-button,
.Toastify__toast--error .Toastify__close-button {
  color: var(--white) !important;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100 !important;
}

.Toastify__toast--success .Toastify--animate-icon svg,
.Toastify__toast--error .Toastify--animate-icon svg {
  fill: var(--white);
}

.notification-toast {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification-toast-header {
  color: var(--white);
  font-size: 17px !important;
  font-weight: 700;
  font-family: var(--pboldfamily);
  line-height: 1;
}

.notification-toast-text {
  font-size: 14px !important;
  color: var(--white);
}

#followModalPopup .modal-content {
  padding: 15px !important;
  border-radius: 5px !important;
  border: none;
}

#followModalPopup .modal-header {
  padding: 0 15px !important;
  border-bottom: none;
  position: relative;
}

#followModalPopup .modal-footer {
  background: rgba(248, 249, 250, 1) !important;
  border-color: rgba(248, 249, 250, 1) !important;
  margin: 0 -15px -15px !important;
  border-radius: 0 0 5px 5px !important;
  padding: 0.65rem !important;
}

#followModalPopup .btn {
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 6px 18px !important;
  outline: none !important;
}

#profileDropdown .dropdown-toggle::after {
  display: none;
}

#loginmodalid .form-check-input,
#signupmodalid .form-check-input {
  margin-top: 0.2rem !important;
}

#newsmodalid .btn-close,
#noticesmodalid .btn-close,
#deleteCartItemModal .btn-close {
  margin-top: -0.2rem !important;
  font-size: 12px !important
}

.page-item:not(:first-child) .page-link {
  margin-left: unset !important;
}

.disclaimer-text {
  font-size: 12px !important;
  font-weight: 100 !important;
  line-height: 17px !important;
  display: inline-block !important;
  width: 100% !important;
  color: var(--default-color);
  font-style: italic;
  letter-spacing: .41px;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.packages_ImageBg {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-background {
  background: #F7F7F7;
}

/**department */
.Categories_categoryist {
  background: var(--white);
}

.home-section {
  padding: 28px 0;
}

.home-section .heading-h2 {
  text-align: center;
  padding-bottom: 28px !important;
  font-size: 2rem !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, .5) !important;
  margin-bottom: 0 !important;
}

.full_banner {
  background-size: cover !important;
  background-position: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  background: linear-gradient(rgba(000, 0, 0, 0.55),
      rgba(000, 0, 0, 0.55)), url(../assets/img/BazaarTitle.jpg) no-repeat;
}

.search_blocks.shor_banner_form {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.search_blocks.shor_banner_form .form-control {
  height: 62px;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden !important;
}

.search_blocks.shor_banner_form input {
  border: none !important;
  outline: none !important;
  overflow: hidden !important;
}

.search_blocks.shor_banner_form input:focus {
  outline: none !important;
  box-shadow: none;
  overflow: hidden !important;
}

button.btn.search_btn {
  border: none;
  background: var(--white);
  color: var(--main-green-color) !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 6px 6px 0;
}

.short_banner_caption.center {
  text-align: center;
}

.short_banner_caption h2 {
  color: #fff;
  font-size: 35px !important;
  line-height: 1.2;
  font-weight: 600;
  font-family: var(--pboldfamily);
}

.short_banner_caption p {
  font-size: 16px;
  color: #fff;
}

.Title_titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.Title_titleWrapper h2 {
  font-weight: 500;
  color: rgba(0, 0, 0, .5) !important;
  font-size: 20px !important;
  line-height: 25px;
  letter-spacing: .38px;
  font-family: var(--pboldfamily);
  margin-right: 6px;
  margin-bottom: 0;
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-box {
  background-color: #f8f8f8;
  margin: 0px auto;
  display: block;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320))) 8px;
  z-index: 0;
}

.category-box img {
  width: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320)));
  height: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320)));
  object-fit: contain;
  margin: 0 auto;

}

.category-box h5 {
  color: #222;
  margin-top: 12px;
  font-weight: 500;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320))) !important;
  line-height: 1.2;
  margin-bottom: 0;
}

.mobile-app-wrap .container {
  max-width: 1170px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.callback-inner {
  background-image: radial-gradient(at top left, #6E9D61, #DEEBDD);
  border-radius: 25px;
  position: relative;
  padding: 0 50px;
  z-index: 2;
}

.callback-image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.about-section {
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
}

.partnetBox {
  padding: 20px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: var(--white);
  min-height: 200px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.partnetBox img {
  object-fit: contain;
}

/* swiper */
.swiper_carouselWrapper {
  position: relative;
  overflow: hidden
}

.swiper_carouselWrapper:before,
.swiper_carouselWrapper:after {
  content: "";
  position: absolute;
  top: 0;
  width: calc((100vw - 858px)/2);
  height: 282px;
  z-index: 10
}

.swiper_carouselWrapper:before {
  left: 0;
  background: linear-gradient(90deg, #fff 27.2%, hsla(0, 0%, 100%, .835) 70.43%, hsla(0, 0%, 100%, .655) 102.98%, hsla(0, 0%, 100%, 0) 132.51%)
}

.swiper_carouselWrapper:after {
  right: 0;
  background: linear-gradient(90deg, #fff 27.2%, hsla(0, 0%, 100%, .835) 70.43%, hsla(0, 0%, 100%, .655) 102.98%, hsla(0, 0%, 100%, 0) 132.51%)
}

.swiper_carouselWrapper .swiper {
  padding: 0 calc((100vw - 858px)/2)
}

.swiper_carouselWrapper .swiper-slide {
  width: auto;
  margin-left: 12px
}

.swiper_carouselWrapper .swiper_carouselItemWrap {
  position: relative;
  height: 282px;
  overflow: hidden
}

.swiper_carouselWrapper .swiper_carouselItemWrap .swiper_carouselItem_ItemWrap {
  height: 282px;
  width: 411px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible;
  border-radius: 10px;
  background-position: 50%;
  overflow: hidden
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  z-index: 100;
  border-radius: 50% !important;
  background-color: #F2F3F4 !important;
  color: #7E7E7E !important;
  border-radius: 6px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 20px !important;
  line-height: 44px !important;
}

.araoundLocationCard {
  overflow: hidden;
  padding: 8px;
  background-color: var(--white);
  border-radius: 6px;
}

.sub_cardHeader {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
}

.sub_cardHeader h2 {
  font-weight: 500;
  font-size: 18px !important;
  letter-spacing: .41px !important;
  color: #222 !important;
  display: inline-block;
  padding-left: 3px;
  padding-bottom: 10px;
  margin-bottom: 0 !important;
}

.swiper-wrapper,
.swiper-slide {
  height: unset;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: #bbb;
  border-radius: 3px;
  outline: 2px solid #fff;
  outline-offset: -2px;
}

.swiper_swiper_slide .swiper-slide {
  width: auto !important;
  margin-left: 0px !important;
}

.header-shopping-cart-icon i {
  font-size: 22px !important;
  cursor: pointer;
  color: #333 !important;
}

.am-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.horizontalMenu {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

#addCartCounter.badge {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-green-color);
  font-size: 12px;
  padding: 0;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 100%;
}

.section-head h1,
.static-page-header-title {
  font-size: 30px !important;
  font-weight: 700 !important;
  margin-bottom: 7px !important;
  color: #555 !important;
  line-height: 22px;
  letter-spacing: 0.02rem;
  font-family: var(--pboldfamily);
}

.section-head h1 {
  margin-bottom: 12px !important;
}

.static-page-header-subtitle {
  color: var(--main-green-color);
  font-size: 14px;
  letter-spacing: 0.03rem;
  font-weight: 300;
}

.content-body h1 {
  margin-bottom: 14px !important;
  color: #777;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  letter-spacing: 0;
}

.content-body p {
  font-size: 14px !important;
  color: #777777 !important;
  line-height: 26px !important;
  font-weight: 300;
  letter-spacing: 0;
  text-align: left;
}

b, strong {
  font-family: var(--pboldfamily);
}

.footer-subtitle {
  font-size: 14px;
  list-style: none;
  padding: 0;
  display: block;
  font-weight: 600;
  text-align: left;
  line-height: 19px;
  font-family: var(--pboldfamily);
  color: #5e5e5e !important;
}

.font-sm-about {
  color: #777777;
  font-size: 15px !important;
  line-height: 26px !important;
}

.section-head h1,
.static-page-header-title {
  font-size: 30px !important;
  font-weight: 700 !important;
  margin-bottom: 7px !important;
  color: #555 !important;
  line-height: 22px;
  letter-spacing: 0.02rem;
  font-family: var(--pboldfamily);
}

.section-head h1 {
  margin-bottom: 12px !important;
}

.static-page-header-subtitle {
  color: var(--main-green-color);
  font-size: 14px;
  letter-spacing: 0.03rem;
  font-weight: 300;
}

.content-body h1 {
  margin-bottom: 14px !important;
  color: #777;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  letter-spacing: 0;
}

.content-body p {
  font-size: 14px !important;
  color: #777777 !important;
  line-height: 26px !important;
  font-weight: 300;
  letter-spacing: 0;
  text-align: left;
}

.product_block_wrap {
  padding: 1.2rem;
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
}

.commodity_img img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.commodity-flex-padding-left {
  padding-left: 1.5rem;
}

.single-commodity {
  border-bottom: 1px solid #e5e5e5;
}

.commodity-container-padding {
  padding-left: 75px;
  padding-right: 75px;
}

.disclaimer-title {
  font-weight: 600;
  margin-bottom: 7px;
}

.disclaimer-div {
  font-size: 13px;
  color: #878c9f;
  font-family: var(--pboldfamily);
}

.default-color {
  color: var(--default-color) !important;
}

.header_search_blocks.shor_banner_form {
  height: 40px;
  width: 35%;
  position: relative;
}

.header_search_blocks.shor_banner_form .form-control,
.mobile_filter_search_blocks.shor_banner_form .form-control,
.search_filter_blocks.shor_banner_form .form-control {
  border-radius: 10px 0px 0px 10px !important;
}

.header_search_blocks.shor_banner_form .input-group-text,
.mobile_filter_search_blocks.shor_form .input-group-text,
.search_filter_blocks.shor_banner_form .input-group-text,
.search_blocks.shor_banner_form .input-group-text {
  border-radius: 0px 10px 10px 0px !important;
}

.header_search_blocks.shor_banner_form input {
  outline: none !important;
  border-right: 0 !important
}

.header_search_blocks.shor_banner_form input:focus {
  outline: none !important;
  box-shadow: none;
}

.search-input-border {
  border-radius: 10px !important;
}

.product-detail-box h2 {
  font-weight: 700 !important;
  color: #222 !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  font-size: 19px !important;
  line-height: 20px !important;
  font-family: var(--pboldfamily);
}

.product-detail-box .price {
  font-size: 18px !important;
  color: #222;
  font-weight: 300 !important;
}

.product-detail-title {
  font-size: 16px !important;
  color: #140c40 !important;
  font-family: var(--pboldfamily);
}

.product-details-description {
  font-size: 14px !important;
  color: #726d73 !important;
  line-height: 25px;
  letter-spacing: .41px
}

.border-product {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px dashed #ddd;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.card-header-title {
  font-weight: 500;
  font-size: 18px !important;
  letter-spacing: .41px !important;
  color: #333 !important;
  width: 100%;
}

@media (max-width: 1080px) {
  .avatar {
    max-width: inherit;
  }

  .header-form {
    display: none;
    margin: 10px 0px 0px;
  }

  .div-content-area {
    padding: 10px 30px 30px 30px;
  }

  .bazaar-title-bgimage,
  .commodity-title-bgimage,
  .news-notice-title-bgimage,
  .store-title-bgimage {
    margin-bottom: 1.5rem !important;
  }
}

@media (max-width: 480px) {
  .app-header1 .header-brand {
    min-width: auto;
  }
}

@media (max-width: 767px) {
  .avatar {
    max-width: inherit;
  }

  .header-brand {
    line-height: 2.7rem;
  }

  .header .input-icon.mt-2 {
    margin-top: 5px !important;
  }
}

/*Large devices*/

@media (min-width: 1200px) {
  .full_banner {
    height: 50vh;
  }

  body {
    font-size: var(--base-font-size) * 1.3 !important;
    line-height: var(--base-line-height) !important;
  }

  .chart-wrapper {
    width: 420px !important;
    height: 208px !important;
  }

  #taskViewTable>tbody>tr>td,
  #noteViewTable>tbody>tr>td {
    line-height: 1.42857 !important;
    padding: 6px !important;
    vertical-align: middle !important;
  }

  .stepwizard-step {
    min-width: 185px;
  }

  .tradestepwizard-step {
    min-width: 378px;
  }

  .static-page-img-lg {
    width: 80% !important;
  }

  .profileImageModalBody {
    height: 650;
  }

  .table.custom-table>tbody>tr>td,
  .table.custom-table>thead>tr>th {
    padding: 12px !important;
    font-size: 13px !important;
  }

  .publicCard,
  .news-notice-publiccard {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .commodity-title {
    font-size: var(--bodysize) !important;
  }

  .usertrade-h4 {
    font-size: 13px !important;
  }

  #varificationCard {
    width: 500px;
  }

  .max-w-screen {
    max-width: 1280px;
  }
}

/*Large devices (desktops, 992px and up)*/

@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    font-size: var(--base-font-size) * 1.3 !important;
    line-height: var(--base-line-height) !important;
  }

  .bottom_row .scroll_cont {
    width: calc(100% + 14px) !important;
  }

  .publicCard,
  .news-notice-publiccard {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #taskViewTable>tbody>tr>td,
  #noteViewTable>tbody>tr>td {
    line-height: 1.42857 !important;
    padding: 6px !important;
    vertical-align: middle !important;
  }

  .stepwizard-step {
    min-width: 155px;
  }

  .table.custom-table>tbody>tr>td,
  .table.custom-table>thead>tr>th {
    padding: 12px !important;
    font-size: 13px !important;
  }

  .profileImageModalBody {
    height: 650;
  }

  .tradestepwizard-step {
    min-width: 325px;
  }

  .commodity-title {
    font-size: 13px !important;
  }

  .header-app-content {
    height: auto;
    min-height: 75vh !important;
  }

  .side-app {
    min-height: 75vh !important;
  }

  #noticePanelBody,
  #newsPanelBody {
    height: 890px !important;
  }

  .setting-ui {
    grid-column-gap: 0 !important;
  }

  #varificationCard {
    width: 500px;
  }

  .about-ibox p {
    height: 90px !important;
  }

  .about-ibox p {
    height: 90px !important;
  }
}

/*Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) and (max-width: 768px) {
  body {
    font-size: var(--base-font-size) * 1.3 !important;
    line-height: var(--base-line-height) !important;
  }

  .bottom_row .scroll_cont {
    width: calc(100% + 14px) !important;
  }

  .properties_item {
    min-width: 360px;
    margin: 0px auto 6px;
  }

  .chartjs-render-monitor {
    height: 213px !important;
  }

  #taskViewTable>tbody>tr>td,
  #noteViewTable>tbody>tr>td {
    line-height: 1.42857 !important;
    padding: 6px !important;
    vertical-align: middle !important;
  }

  .publicCard,
  .news-notice-publiccard {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .stepwizard-step {
    min-width: 115px;
  }

  .dealdescription {
    width: 20vh !important;
  }

  .paragraph-body-padding-right,
  .paragraph-body-padding-left {
    padding: unset !important;
  }

  .userNameText {
    display: none !important;
  }

  .paragraph-body {
    color: rgb(50, 51, 56);
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .table.custom-table>tbody>tr>td,
  .table.custom-table>thead>tr>th {
    padding: 12px !important;
    font-size: 13px !important;
  }

  .profileImageModalBody {
    height: 650px;
  }

  .tradestepwizard-step {
    min-width: 240px;
  }

  .commodity-title {
    font-size: 13px !important;
  }

  .header-app-content {
    height: auto;
    min-height: 70vh !important;
  }

  .side-app {
    min-height: 70vh !important;
  }

  #noticePanelBody,
  #newsPanelBody {
    height: 640px !important;
  }

  #varificationCard {
    width: 500px;
  }

  .btn-outline-gray,
  .btn-green,
  .btn-reset {
    font-size: 11px !important;
  }

  #footerDiv .container {
    max-width: 900px;
  }

  .about-ibox p {
    height: unset !important;
  }

  .about-ibox p {
    height: unset !important;
  }
}

/* mobile */

@media (min-width: 576px) and (max-width: 767.98px),
(max-width: 575.98px) {
  .background-color {
    background-color: #f3f3f3 !important;
  }

  body {
    font-size: var(--base-font-size) * 1.2 !important;
    line-height: var(--base-line-height) * 1.2 !important;
    height: unset !important;
  }

  .header {
    top: -1px !important;
  }

  .header-app-content {
    min-height: unset !important;
  }

  .Navigation_navigation#navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 64px;
    background-color: var(--white);
    z-index: 300 !important;
  }

  .Navigation_navigationList {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center
  }

  .Navigation_navigationListItemBox {
    width: 60px;
    position: relative
  }

  .Navigation_navigationListItem {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0
  }

  .Navigation_navigationListItem .Navigation_navigationListItemIcon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .Navigation_navigationListItemName {
    white-space: nowrap;
    text-align: center;
    color: #999;
    font-size: 11px;
    margin-top: 6px
  }

  .Navigation_navigationListItemBox.active .Navigation_navigationListItemIcon,
  .Navigation_navigationListItemBox.active .Navigation_navigationListItemName {
    color: var(--main-green-color) !important;
  }

  .Navigation_navigationListItemBox:focus .Navigation_navigationListItemIcon,
  .Navigation_navigationListItemBox:hover .Navigation_navigationListItemName {
    color: var(--main-green-color) !important;
  }

  .card-border {
    border-radius: unset !important;
  }

  .featured_panel {
    border-top-left-radius: unset !important;
  }

  .pp_img img {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
  }

  .properties_item {
    border-radius: unset !important;
  }

  .ibox {
    -webkit-border-radius: unset;
    -moz-border-radius: unset;
    border-radius: unset;
  }

  .padding-0 {
    padding: 0 8px 0 6px !important;
  }

  #newsdatatable .row,
  #noticeDataTable .row {
    margin-left: -8px !important;
  }

  #newsdatatable .row,
  #propertydatatable .row,
  #dealViewDiv .row,
  .platformDiv .row,
  .servicesMainDiv .row,
  .industryMainDiv .row {
    margin-right: 0 !important;
  }

  .aboutDiv .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .testimonial-card {
    margin-left: 2px !important;
  }

  .publicCard {
    padding-left: 5px;
    padding-right: 5px;
  }

  .news-notice-publiccard {
    padding-right: 0.25rem !important;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link.hover {
    color: var(--main-green-color);
    background-color: transparent;
    border-color: transparent transparent var(--white) !important;
    border-bottom: 2px solid var(--main-green-color) !important;
    font-size: var(--bodysize);
    font-weight: 600;
    font-family: var(--pboldfamily);
  }

  .weblist-tabs-container .nav-tabs .nav-link.active {
    border-color: transparent transparent var(--white) !important;
    border-bottom: 2px solid var(--main-green-color) !important;
  }

  .nav-tabs .nav-link {
    padding: 10px !important;
    font-family: var(--pboldfamily);
  }

  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: var(--bodysize);
    font-weight: 600;
  }

  .nav-link:hover {
    border: none;
  }

  .mobile-tab-content {
    border: 1px solid var(--background-color) !important;
  }

  .mobile-tab-content .panel-body {
    background: var(--white);
    position: relative !important;
    padding: 0 8px 0 6px;
  }

  .mobile-side-app {
    padding: 50px 0px 50px 0px;
  }

  .add-side-app {
    padding: 65px 10px 60px 10px;
  }

  .edit-side-app {
    padding: 65px 15px 60px 15px;
  }

  hr {
    margin: 0.25rem !important;
  }

  .existing-images-zone>.existing-preview-image {
    height: auto !important;
    width: 48.5% !important;
  }

  .existing-images-zone>.existing-preview-image:nth-of-type(odd) {
    margin-right: 6px;
  }

  .existing-images-zone>.existing-preview-image:nth-of-type(even) {
    margin-right: 0;
  }

  .add-preview-images-zone>.preview-image {
    width: 47% !important;
    margin-right: 6px;
  }

  .imagetitle {
    bottom: 0px !important;
    width: 100% !important;
    left: unset !important;
  }

  .property-bg-holder,
  .news-bg-holder,
  .notice-bg-holder {
    height: 95vh !important;
  }

  .header-search {
    margin-right: unset !important;
  }

  #taskViewTable>tbody>tr>td,
  #noteViewTable>tbody>tr>td {
    line-height: 1.42857 !important;
    padding: 0px !important;
    vertical-align: middle !important;
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    line-height: 15px !important;
    top: 9px !important;
  }

  .stepwizard-step p,
  .tradestepwizard-step p {
    padding: 4px;
  }

  #asset-tabs .nav-tabs>li {
    font-weight: 800;
    font-size: var(--bodysize);
    text-align: center;
    width: 100%;
    background-color: #f3f3f3;
  }

  #asset-tabs .nav-tabs {
    box-shadow: var(--box-shadow) !important;
  }

  #asset-tabs .nav-tabs .nav-link {
    padding-bottom: 15px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 15px !important;
  }

  #asset-tabs .nav-tabs .nav-link.active {
    color: var(--main-green-color) !important;
    background-color: var(--white) !important;
    box-shadow: unset !important;
  }

  .popup-form .btn2 {
    width: unset !important;
  }

  #newsdatatable .share-info {
    position: absolute !important;
    right: 8px;
  }

  .input-wrapper .input {
    position: absolute;
    top: 18px !important;
    opacity: 0 !important;
    left: 81px !important;
    cursor: pointer;
  }

  .dealdescription {
    width: 10vh !important;
  }

  .asset-list table tr td {
    padding: 15px 8px;
  }

  .services-div {
    width: 50% !important;
  }

  .services-expertise-div {
    width: 32.5% !important;
  }

  .paragraph-body-padding-right,
  .paragraph-body-padding-left {
    padding: unset !important;
  }

  .userNameText {
    display: none !important;
  }

  .mobile-container-fluid {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .footer-section-1 {
    padding-top: 10px;
  }

  .footer-menu-list {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .footer-menu-item {
    margin: 0;
  }

  .footer-section-1 ul li {
    list-style: none;
  }

  .footer-sublist li.ftsublink-li {
    margin-bottom: 10px;
  }

  .footer-sublist {
    padding-bottom: 5px !important;
  }

  .footer-menu-item,
  .footer-menu-item--sub {
    margin: 0;
  }

  .footer-menu-list .cardfooter:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f106";
    float: right;
    color: var(--default-color);
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    font-size: 12px !important;
  }

  .footer-menu-list .cardfooter.collapsed:before,
  .footer-menu-link {
    content: "\f107";
  }

  .filter_wraps {
    padding: 1rem 0;
  }

  .footer-menu-item--sub {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .total-record {
    font-size: 13px !important;
    bottom: 9 !important;
  }

  .bottom_row .scroll_cont {
    width: 100% !important;
    overflow: unset !important;
  }

  .bottom_row {
    position: unset !important;
  }

  .vs_cont {
    top: calc(100% - 0.5rem) !important;
  }

  .dealstage {
    width: 5rem !important;
  }

  .main-search-form {
    width: unset !important;
  }

  .search-type {
    padding-bottom: 12px;
  }

  .search-type label {
    padding: 9px 7px !important;
    font-size: var(--bodysize);
    margin: 0;
  }

  .sbox,
  .home-sbox {
    top: 40vh !important;
  }

  .input-sm {
    border: 1px solid var(--white) !important;
  }

  .platformDiv .main-img {
    height: 100;
  }

  .profileImageModalBody {
    height: 400;
  }

  .entry-prop {
    max-width: 100%;
  }

  .contactViewTable td,
  .companyViewTable td {
    padding: 0.2rem !important;
  }

  .ui-autocomplete {
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .tradestepwizard-step {
    min-width: 110px;
  }

  .hight-100 {
    height: unset !important;
  }

  .side-app {
    min-height: unset !important;
  }

  .commodity-title {
    font-size: 13px !important;
  }

  .mobile-nav-link {
    padding: 0.5rem 0rem !important;
  }

  .market-dropdown {
    min-width: 12rem !important;
  }

  .description-font {
    font-size: 14px !important;
  }

  .total-record-favorite {
    position: unset;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .header-position p {
    width: unset !important;
  }

  .header-position {
    padding: 5px;
  }

  .input-lg {
    font-size: 14px;
    border-radius: 0 !important;
  }

  .header {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .trade-setup-content .panel-body {
    padding: 0 !important;
  }

  #editTrade .panel-body,
  #editTradeLocation .panel-body,
  #editTradeProperty .panel-body,
  #editTradeImage .panel-body {
    padding: 5 0 !important;
  }

  .trade-carousel-img {
    border-radius: 0px;
  }

  #goog-gt-tt {
    width: 95% !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .commodityCard {
    padding-left: 0 !important;
  }

  .commodityCard:nth-of-type(odd) {
    padding-right: 8px !important;
  }

  .commodityCard:nth-of-type(even) {
    padding-right: 0 !important;
  }

  .gear.one,
  .gear.three {
    display: none !important;
  }

  .gear.two {
    top: unset !important;
  }

  .apiNotFoundloading {
    height: 100%;
  }

  .apiNotFoundloading h1 {
    margin: 50px auto 0 auto !important;
  }

  .speciallities-chk .custom-control-label {
    font-size: 13px;
    margin-bottom: 0.5rem;
  }

  .speciallities-chk .custom-control-label::before,
  .speciallities-chk .custom-control-label::after {
    top: -0rem;
  }

  #storeDetails .row,
  #tradDetails .row,
  #commodityDetail .row {
    margin: 0;
  }

  .store-info {
    margin-bottom: 0;
  }

  .store-logo {
    max-width: 70px;
  }

  .store-logo>img {
    max-width: 70px;
  }

  .add-edit-store-logo,
  .add-edit-cover {
    height: 30px;
    width: 30px;
    line-height: 30px;
    right: 5px;
  }

  .add-edit-store-logo i,
  .add-edit-cover i {
    line-height: 30px;
  }

  .modal-close-icon {
    right: 18px;
    position: absolute;
    font-size: 18px;
    top: 18px;
    cursor: pointer;
  }

  .trade-tag {
    width: unset;
  }

  .trade-details-page h2 {
    font-size: 19px !important;
  }

  .margin-bottom-2 {
    margin-bottom: 5px !important;
  }

  .bazaar-title-bgimage,
  .commodity-title-bgimage,
  .news-notice-title-bgimage,
  .store-title-bgimage {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .search-box .form-group .form-control {
    background-color: var(--white);
    border: 1px solid rgb(235, 235, 235) !important;
    border-radius: 0px;
    font-size: var(--bodysize) !important;
    color: var(--default-color) !important;
    height: 50px;
  }

  .search-button .btn {
    height: 50px;
    font-size: 14px !important;
    border-radius: 10px !important;
    border: 1px solid rgb(235, 235, 235) !important;
    padding: 0.375rem 0.9rem !important;
  }

  .follow-btn {
    padding: 5px 10px;
  }

  .notfound-page.row {
    margin: 0;
  }

  .right-nav {
    display: none !important;
  }

  .start-text {
    font-size: var(--bodysize) !important;
  }

  .card-title-h2 {
    font-size: 14px;
  }

  .btn-store-start {
    font-size: 10px !important;
  }

  .userTrade-search-box {
    border-radius: 0;
  }

  .home-banner {
    background: linear-gradient(120deg, #21170e 0%, transparent 100%),
      url("../assets/img/homeBackground/HomeMedium.jpg");
    background-size: cover !important;
    background-position: 0px 30px !important;
    padding: 70px 12px 30px !important;
  }

  #homeBottomCard .row,
  #address-list .row {
    margin: 0;
  }

  #homeBottomCard .column,
  #address-list .column {
    padding: 0;
  }

  .userTradeCard-row {
    margin: 0;
  }

  #loginmodalid .modal-body,
  #signupmodalid .modal-body,
  #forgotpawordupmodalid .modal-body {
    padding: 1rem;
  }

  .footer-default-text {
    padding: 7px !important;
  }

  .small-font-size {
    font-size: 10px !important;
  }

  #tradeDetails .default-trade-img {
    height: 250px !important;
  }

  .greyBoxRow {
    display: flex;
    width: 100% !important;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .greyBoxRgtCol {
    padding-left: 5px;
  }

  .greyBoxLblCol {
    color: #666 !important;
  }

  .greyBoxRgtCol, .greyBoxRgtCol a {
    color: #222 !important;
    width: 100% !important;
  }

  .crt-font-size {
    font-size: 12px;
  }

  .modalDivider-root {
    width: auto !important;
  }

  .userTradeListRow {
    width: 100%;
    display: flex;
  }

  .userTradeListLeftCol {
    width: 55%;
    font-size: 14px;
    display: inline-block;
    text-align: left;
  }

  .userTradeListRightCol {
    width: 45%;
    font-size: 14px !important;
    display: inline-block;
    text-align: right;
  }

  #addaddressCard .form-item label {
    font-size: 13px;
  }

  #addaddressCard .form-item .active {
    font-size: 13px;
    top: -5px;
  }

  #addaddressCard .form-control,
  #addaddressCard .form-select {
    height: 40px;
  }

  .grid-templates-columns {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: flex;
    flex-direction: column;
    gap: unset;
  }

  .prideDetail-list.grid-templates-columns {
    gap: unset !important;
  }

  .cartCheckBox:checked:after {
    top: 0 !important;
  }

  .cartCheckBox {
    vertical-align: text-bottom;
  }

  .trade-details-list .payment-badge {
    padding: 0.25em 0.4em !important;
  }

  .trade-details-list ul li+li {
    margin-top: 8px;
  }

  .addresses-list__item,
  .chart-img {
    border-radius: 0;
  }

  .qtyInput {
    width: 46px !important;
    height: 20px !important;
    /* height: 24px !important; */
    font-size: 12px !important;
  }

  .button-negative,
  .button-positive {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  /*App browser*/
  .appmodal-offcanvas {
    position: fixed;
    bottom: -1px;
    z-index: 1050;
    display: flex;
    width: 100%;
    outline: 0;
    margin: 0;
  }

  .appmodal-offcanvas .modal-content {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .appmodal-offcanvas .modal-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 20px;
    padding: 12px 0;
  }

  .appmodal-offcanvas .modal-header .modal-title {
    color: #1a1a1b !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: var(--pboldfamily);
  }

  #appModalId.modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0) !important;
  }

  .appmodal-offcanvas .modal-body {
    padding: 16px 20px !important;
    vertical-align: top;
  }

  .appmodal-offcanvas .imageBtnWrapper {
    background-color: transparent;
    border: 1px solid #edeff1;
    border-radius: 8px;
    margin-right: 12px;
    padding: 5px;
  }

  .appmodal-offcanvas .imageBtnWrapper img {
    width: 30px !important;
    height: 30px !important;
  }

  .appmodal-offcanvas .app-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1a1a1b;
    font-size: var(--bodysize);
    font-weight: 700;
    line-height: 32px;
    margin-right: 100px;
    position: relative;
    font-family: var(--pboldfamily);
  }

  .actionButton {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid var(--main-green-color);
    border-radius: 3px;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 35px;
    outline: none;
    padding: 0 12px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 94px;
    background-color: var(--main-green-color);
    color: var(--white);
    font-family: var(--pboldfamily);
  }

  .graybutton {
    background-color: #f6f7f8 !important;
    border: 1px solid #f6f7f8 !important;
    color: #787c7e !important;
  }

  .div-content-area {
    padding: 10px 30px 30px 15px;
  }

  #contactus-div .row {
    margin: 0;
  }

  .contactus-column {
    padding: 0 !important;
  }

  .total-amount {
    border-top: 1px solid #dee2e6;
    padding-top: 14px !important;
    margin-top: 14px !important;
  }

  .footer-list-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-list-wrap ul.footer-list a.ftlink-li {
    width: 100% !important;
    padding-bottom: 15px;
    /* padding-bottom: 15px; */
  }

  .footer-list-wrap a h2.ft-title {
    font-size: var(--bodysize) !important;
    font-weight: 600 !important;
    font-family: var(--pboldfamily);
    margin-bottom: 15px;
  }

  .padding-col-0 {
    padding: 0;
  }

  .qty-div .btn,
  #contactStore.btn {
    padding: 0.375rem 0.75rem !important;
  }

  .half-button {
    font-size: var(--bodysize) !important;
  }

  .half-button i {
    font-size: var(--bodysize) !important;
  }

  .py-10 {
    padding-top: 3.75rem;
    padding-bottom: 2rem;
  }

  .filter-btn {
    width: 70px !important;
  }

  .department-search-div {
    border-radius: 0 !important;
  }

  #SortBy {
    padding: 0.5rem 1.75rem 0.5rem 0.75rem !important;
  }

  #departmentModal {
    margin-bottom: 60px;
  }

  .cart-menu-title {
    font-size: 15px !important;
  }

  .bazaar-title-bgimage {
    margin-bottom: 0 !important;
  }

  .commodity-card-content-wrap-price,
  .commodity-card-content-wrap h2 {
    font-size: 14px !important;
  }

  .public-page-app-content {
    padding-top: 60px;
  }

  .mobile-app-wrap {
    padding: 50px 0 100px;
  }

  .mobile-app-wrap h1 {
    font-size: 30px !important;
  }

  .mobile-container {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .errwordcount {
    float: unset;
  }

  .saveComment-btn {
    margin-top: 0;
  }

  .margin-bottom-1 {
    margin-bottom: 3px;
  }

  .order-img {
    width: 60px;
    height: 60px;
    margin-right: 5px;
  }

  .bg-11 {
    background: radial-gradient(at top left, #6E9D61, #DEEBDD) !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .section-title h3 {
    font-size: 18px !important;
  }

  .full-paragraph-title-wrapper {
    font-size: 20px !important;
  }

  .location-gps {
    right: 12px !important;
  }

  .store-cover {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 114px !important;
  }

  .packages_ImageBg {
    height: 59vw !important;
    background-position: center center !important;
    width: 100% !important;
  }

  .homepage_main {
    padding-left: 12px;
    padding-bottom: 10px;
  }

  .home-section {
    padding: 25px 10px;
  }

  .home-section .heading-h2 {
    text-align: start;
    padding-bottom: 25px !important;
    font-size: 18px !important;
    line-height: 1.1 !important;
    font-weight: 600 !important;
    letter-spacing: .41px !important;
    color: #222;
    font-family: var(--pboldfamily);
    margin-bottom: 0 !important;
  }

  .SectionTitle_moreLink {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .view-all-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
    line-height: 24px;
    text-align: right;
    color: var(--ecm-blue);
  }

  .view-all-link span {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -.2px;
    padding-bottom: 15px;
  }

  .store-section-background {
    background-color: rgba(250, 183, 59, .1);
  }

  .Title_titleWrapper h2 {
    font-size: 16px !important;
  }

  .full_banner {
    padding: 70px 12px 30px !important;
    background-position: 0px 30px !important;
    min-height: unset !important;
  }

  .callback-inner {
    padding: 0 30px !important;
  }

  .callback-content {
    padding: 40px 0 60px;
  }

  .araoundLocationCard {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .footer-list-wrap ul.footer-list {
    display: block;
  }

  .commodity_img img {
    width: 100%;
    height: 150px !important;
    object-fit: contain;
    border: 0 !important;
  }

  .search_filter_blocks.shor_banner_form {
    width: unset !important;
    max-width: 100%;
    position: relative;
    right: 60px;
    left: 10px;
  }

  .search_filter_blocks.shor_banner_form .form-control {
    height: 50px;
    border-radius: 10px 0px 0px 10px;
  }

  .search_filter_blocks.shor_banner_form input {
    border: none !important;
    outline: none !important;
  }

  .search_filter_blocks.shor_banner_form input:focus {
    outline: none !important;
    box-shadow: none;
  }

  .partners-img-container {
    height: 150px;
    width: 100%;
    text-align: center;
  }

  .partners-img-container .partners-img img {
    height: 100%;
  }

  .top-space {
    padding-top: 32px;
    padding-bottom: 60px;
  }

  .product-detail-box h2 {
    font-weight: 700 !important;
    color: #222 !important;
    line-height: 1.3;
    font-size: 16px !important;
    margin-bottom: 1px !important;
    font-family: var(--pboldfamily);
  }

  .product-detail-box .price {
    display: flex;
    align-self: center;
  }

  .product-detail-box .price span {
    font-size: 16px !important;
    color: var(--main-green-color);
    font-weight: 300 !important;
  }

  .divider {
    height: 8px;
    width: 100%;
    margin: 12px 0;
    background-color: #f3f3f3;
  }

  .line-height-0 {
    line-height: 0 !important;
  }

  .store-detail .media {
    display: flex;
    align-items: center;
  }

  .store-detail .media img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 20px;
  }

  .store-detail .media h2 {
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 1.3 !important;
    margin: 0 !important;
    color: #222222 !important;
  }

  .store-detail .media .media-body h6 {
    margin-bottom: 8px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 500;
    margin: 0;
    color: #777777 !important;
  }

  .store-detail .media .media-body .view-btn,
  .store-detail .media .media-body a {
    background-color: var(--main-green-color);
    color: #ffffff !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    padding: 5px 10px !important;
    border-radius: 3px !important;
    font-family: var(--pboldfamily);
  }

  .product-detail-title {
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    color: #222222 !important;
    font-family: var(--pboldfamily);
  }

  .product-detail-title-space {
    margin-bottom: 8px !important;
    line-height: 1.3 !important;
  }

  .product-details-description {
    font-size: 13px !important;
    line-height: 16px !important;
    font-weight: 500;
    color: #726d73 !important;
    margin-bottom: 5px !important;
  }

  .download-app a {
    margin: 0 8px 0 0 !important;
  }

  .card-header-title {
    font-weight: 500;
    font-size: 16px !important;
    letter-spacing: .41px !important;
    color: #333 !important;
    width: 100%;
  }
}

/*iphn 6 7 8*/

@media (min-width: 375px) and (max-width: 667px) {

  .stepwizard-step p,
  .tradestepwizard-step p {
    padding: 4px;
  }

  .paragraph-body {
    color: rgb(50, 51, 56);
    font-size: var(--bodysize) !important;
    line-height: 24px !important;
  }

  .full-paragraph-title-wrapper {
    font-size: 20px !important;
    line-height: 24px;
  }

  .footer-title {
    font-size: var(--bodysize) !important;
  }

  .tradestepwizard-step {
    min-width: 118px;
  }

  .footer-default-text {
    padding: 3.5px !important;
  }

  .goog-te-combo {
    padding: 5.2px !important;
  }
}

/*iphn 6 7 8 plus*/

@media (min-width: 414px) and (max-width: 736px) {

  .stepwizard-step p,
  .tradestepwizard-step p {
    padding: 8px;
  }

  .paragraph-body {
    color: rgb(50, 51, 56);
    font-size: var(--bodysize) !important;
    line-height: 24px !important;
  }

  .full-paragraph-title-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px !important;
    line-height: 24px;
    font-weight: 400;
    color: rgb(50, 51, 56);
  }

  .tradestepwizard-step {
    min-width: 130px;
  }

  .footer-default-text {
    padding: 3.5px !important;
  }

  .goog-te-combo {
    padding: 5.2px !important;
  }
}

/*extra large*/

@media (min-width:1921px) {
  .filter-modal {
    top: 50px !important;
    position: absolute !important;
    overflow-y: scroll;
    margin-top: 16px;
  }

  .filter-modal-header {
    z-index: 1000;
  }
}

@media (min-width: 2000px) {
  .header-app-content {
    min-height: unset !important;
  }

  .explorPageBody,
  .explorPageCount {
    height: 0 !important;
  }

  .app-footer-bottom {
    padding-bottom: 80px;
  }

  body {
    font-size: var(--base-font-size) * 1.6 !important;
    padding: 0 calc((100% - 1600px) / 2) !important;
  }

  .header {
    height: 65px;
    padding-top: 3px;
    max-width: 1600px !important;
  }

  .side-app {
    background-color: var(--background-color);
    min-height: unset !important;
  }

  .banner-image {
    position: relative !important;
  }

  .app {
    margin: auto;
    background-color: var(--background-color);
  }

  .stepwizard-step {
    min-width: 185px;
  }

  .nav-footer .nav-link {
    padding: 0.5rem 0.5rem !important;
  }

  .dealdescription {
    width: 40vh !important;
  }

  .banner {
    padding: 33vh 0 !important;
    position: relative !important;
  }

  .explorPageImage {
    position: fixed;
    max-width: 1920px;
    width: 100%;
    height: 100% !important;
    bottom: unset !important;
    left: unset !important;
    right: unset !important;
    top: 72px !important;
  }

  .vs_cont {
    top: calc(100% - 10.9375rem) !important;
  }

  .sbox,
  .home-sbox {
    top: 32vh !important;
  }

  .bottom_row {
    min-width: 1313px !important;
    width: 100%;
  }

  .static-page-img-lg {
    width: 75% !important;
  }

  .profileImageModalBody {
    height: 600;
  }

  .table.custom-table>tbody>tr>td,
  .table.custom-table>thead>tr>th {
    padding: 16px !important;
    font-size: var(--bodysize) !important;
  }

  .publicCard {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #storeDetails,
  #userTradeDetails {
    padding-top: 0;
  }

  .notfound-page.row {
    margin: 0;
  }

  .notfound-page.column {
    padding: 0;
  }

  .userTradeCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .usertradecol {
    padding-left: 10px;
    padding-right: 10px;
  }

  #userTradeDetails .container-fluid,
  #storeDetails .container-fluid {
    padding: 0;
  }

  .filter-scrollbar {
    height: unset !important;
    overflow: hidden !important;
  }

  .filter-scrollbar:hover {
    overflow: unset;
    margin-right: 0;
  }

  .container-md {
    max-width: 1300px;
  }

  .full_banner {
    height: unset !important;
  }
}

@media (max-width: 585px) {
  .wizard {
    width: 90%;
    height: auto !important;
  }

  .wizard .nav-tabs>li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .wizard li.active:after {
    content: " ";
    position: absolute;
    left: 35%;
  }

  #addaddressCard .form-item label {
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 390px) {
  .testimonial-card {
    width: unset !important;
  }

  .testimonial-outside {
    position: relative;
    left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .small-font-size {
    font-size: 8px !important;
  }
}

@media (min-width: 576px) and (max-width: 1280px) {
  .userNameText {
    display: none !important;
  }
}

/*commodity card responsive*/

@media (min-width: 991px) and (max-width: 1500px) {
  .commodityCard {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .homeCommodityCard {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .tradeCard,
  .storeTradeCard,
  .storeListCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .favouriteTradeCard,
  .storeCard {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .favouriteCommodityCard {
    flex: 0 0 24.8%;
    max-width: 24.8%;
  }

  .userTradeCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  #storeDetails {
    padding-top: 50px;
  }

  .btn-green,
  .btn-light,
  .btn-reset {
    padding: 0.2rem 1rem !important;
    font-size: 14px !important;
  }

  .trade-list-map,
  .store-list-map {
    height: 280px;
  }

  .usertrade-h4 {
    font-size: 12px !important;
  }

  .error {
    font-size: 11px !important;
  }

  .homeTradeCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .horizontalMenu {
    padding-left: 0 !important
  }

  .cart-menu-title {
    font-size: 15px !important;
  }

  .cat-menu-img {
    margin-right: 5px !important;
  }

  .filter-btn {
    width: 60px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .commodityCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .homeCommodityCard {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .favouriteTradeCard,
  .storeCard,
  .homeTradeCard {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tradeCard,
  .storeTradeCard,
  .storeListCard {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .favouriteCommodityCard {
    flex: 0 0 24.8%;
    max-width: 24.8%;
  }

  .userTradeCard {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #storeDetails {
    padding-top: 50px;
  }

  .btn-green,
  .btn-light,
  .btn-reset {
    padding: 0.2rem 1rem !important;
    font-size: 14px !important;
  }

  .addresses-list__item {
    width: calc((100% - 22px - 1px) / 1) !important;
  }

  #ShoppingCardMainDiv .grid-templates-columns {
    gap: unset !important;
    display: block;
  }

  #tradeDetails .container {
    max-width: 903px;
  }

  .cart-menu-title {
    font-size: 15px !important;
  }

  .cat-menu-img {
    margin-right: 5px !important;
  }

  .filter-btn {
    width: 60px;
  }

  .download-app a img {
    width: 80px !important;
    height: 24px !important;
  }

  .commodity-container-padding {
    padding: 0 !important;
  }

  .footer-title,
  .footer-list-wrap li h2.ft-title {
    font-size: 14px !important;
  }

  .footer-info .logo p,
  .footer-sublist li.ftsublink-li,
  .footer-subtitle,
  .goog-te-combo {
    font-size: 11px !important;
  }

  .footer-default-text {
    font-size: 11px !important;
    margin-bottom: 15.5px;
  }
}

@media (max-width: 1199px) and (min-width: 991px) {

  .commodityCard,
  .tradeCard,
  .homeTradeCard,
  .favouriteTradeCard,
  .favouriteCommodityCard,
  .userTradeCard {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .commodityCard,
  .homeCommodityCard {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .tradeCard,
  .storeTradeCard,
  .homeTradeCard {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .favouriteCommodityCard {
    flex: 0 0 24.8%;
    max-width: 24.8%;
  }

  .userTradeCard,
  .storeListCard,
  .storeCard {
    flex: 0 0 50%;
    max-width: 50%;
  }

  #storeDetails {
    padding-top: 50px;
  }

  .btn-green,
  .btn-light,
  .btn-reset {
    padding: 0.2rem 1rem !important;
    font-size: 14px !important;
  }

  .addresses-list__item {
    width: calc((100% - 23px - 1px) / 2) !important;
  }

  #ShoppingCardMainDiv .grid-templates-columns {
    gap: unset !important;
    display: block;
  }

  .favouriteTradeCard {
    flex: 0 0 100%;
    max-width: 100%;
  }

}

@media (max-width: 767px) {

  .commodityCard,
  .homeCommodityCard,
  .favouriteCommodityCard {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tradeCard,
  .homeTradeCard,
  .favouriteTradeCard,
  .userTradeCard,
  .storeTradeCard,
  .storeListCard,
  .storeCard {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .d-flex-unset {
    display: unset;
  }

  #storeDetails {
    padding-top: 0;
  }

  .addresses-list__item {
    width: calc((100% - 11px - 1px) / 1) !important;
  }

  .address-card__body {
    line-height: 20px;
  }

  .storeCard {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*setting card responsive*/

@media (min-width: 1025px) {
  .setting-ui {
    grid-column-gap: 1.6rem;
    grid-auto-flow: column;
  }
}

@media (min-width: 40em) {
  .setting-body {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .security-section {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 1100px),
(max-width: 575.98px) {
  .security-card-title {
    font-size: 17px;
    padding: 0 0 1rem 0;
  }
}

@media (max-width: 991px) {
  .sm-sidebar {
    height: calc(100% - 106px);
    max-width: 100%;
    min-width: 100%;
    background-color: var(--white);
    position: fixed;
    top: 50;
    box-shadow: var(--box-shadow);
    left: 0;
    display: none;
    padding: 15px;
    margin-bottom: 30px;
    z-index: 20000;
    overflow-y: auto !important;
  }

  .search-sidebar_header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 2rem;
    padding: 0 0 0.5rem;
    background-color: var(--white);
    border-bottom: 1px solid #dfe4ec;
    margin-bottom: 0.8rem;
  }

  .search-sidebar_header h4 {
    margin: 0;
    flex: 1;
    font-size: var(--bodysize) !important;
  }

  .simple-sidebar button.bar-item.bar-button.bar-large {
    background: var(--white);
    border: none !important;
    cursor: pointer;
    outline: 0 !important;
    color: #465169;
  }
}

/* safari media */

@media not all and (min-resolution: 0.001dpcm) {

  .footer-default-text {
    padding: 0 !important;
    border: 0 !important;
    background-color: unset !important;
    margin-left: 4px !important;
  }

  @media (-webkit-touch-callout: none) {

    .footer-default-text {
      padding: 4.7px !important;
    }
  }

  .swiper_carouselWrapper .swiper-slide,
  .swiper-slide {
    height: unset;
  }

  .Toastify__toast {
    max-height: unset !important;
    min-height: unset !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (min-width:1920px) {

  .swiper_carouselWrapper:after,
  .swiper_carouselWrapper:before {
    width: calc((100% - 1278px)/2);
    height: 416px;
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap {
    height: 416px
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap .swiper_carouselItem_ItemWrap {
    height: 416px;
    width: 616px
  }

  .swiper_carouselWrapper .swiper {
    padding: 0 calc((100% - 1278px)/2)
  }

  .swiper_carouselWrapper .swiper-slide {
    margin-left: 16px
  }

  .category-box {
    padding: 15px;
  }
}

@media (min-width:1280px)and (max-width:1439px) {

  .swiper_carouselWrapper:after,
  .swiper_carouselWrapper:before {
    width: calc((100vw - 1010px)/2);
    height: 328px;
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap {
    height: 328px
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap .swiper_carouselItem_ItemWrap {
    height: 328px;
    width: 481px
  }

  .swiper_carouselWrapper .swiper {
    padding: 0 calc((100vw - 1010px)/2)
  }

  .swiper_carouselWrapper .swiper-slide {
    margin-left: 12px
  }
}

@media (min-width:1440px)and (max-width:1919px) {

  .swiper_carouselWrapper:after,
  .swiper_carouselWrapper:before {
    width: calc((100vw - 1024px)/2);
    height: 366px;
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap {
    height: 366px
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap .swiper_carouselItem_ItemWrap {
    height: 366px;
    width: 488px
  }

  .swiper_carouselWrapper .swiper {
    padding: 0 calc((100vw - 1024px)/2)
  }

  .swiper_carouselWrapper .swiper-slide {
    margin-left: 16px
  }
}

@media (min-width: 576px) and (max-width: 767.98px),
(max-width: 575.98px) {
  .swiper_carouselWrapper .swiper-slide {
    width: calc(88.7574vw - 19.52663px) !important;
    margin-right: 6px !important;
    margin-left: unset !important;
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap .swiper_carouselItem_ItemWrap {
    height: unset !important;
    width: unset !important;
    background-size: unset !important;
    background-repeat: unset !important;
    overflow: unset !important;
    border-radius: unset !important;
    background-position: unset !important;
    overflow: unset !important;
  }

  .swiper_carouselWrapper .swiper_carouselItemWrap {
    height: unset !important;
  }

  .swiper_carouselWrapper .swiper {
    height: 59vw;
    margin: auto;
  }

  .swiper-pagination {
    display: none !important;
  }

  #storeSwiper .swiper_carouselWrapper .swiper {
    height: 32vw !important;
  }

  #storeSwiper .store-custom-card {
    background-color: var(--white);
  }

  #storeSwiper .swiper_carouselWrapper .swiper-slide {
    margin-right: 0 !important;
  }

  #storeSwiper.home-section {
    padding: 25px 6px 20px 6px !important;
  }

  #storeSwiper.home-section .heading-h2 {
    margin-bottom: 0;
    max-width: calc(100% - 90px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 15px !important;
  }
}